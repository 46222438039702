import { Plain } from '../../constants/colors';
import type { MotifComponent, MotifComponentProps } from '../../motif';

export const modalLightBgVars: MotifComponentProps<MotifComponent.MODAL> = {
  '--modal-bg-color': 'rgba(255, 255, 255, .7)',
  '--modal-close-bg-color': Plain.BLACK,
  '--modal-close-fg-color': Plain.WHITE,
} as const;

export const modalDarkBgVars: MotifComponentProps<MotifComponent.MODAL> = {
  '--modal-bg-color': 'rgba(0, 0, 0, .7)',
  '--modal-close-bg-color': Plain.WHITE,
  '--modal-close-fg-color': Plain.BLACK,
} as const;
