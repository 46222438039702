import { css } from '@emotion/css';

import { nonMobileMediaQuery } from '../../../constants';
import { m } from '../../../motif/m';

export const footerItemContainerCss = css`
  margin: ${m('--spacing-s')} 0;

  ${nonMobileMediaQuery} {
    margin: ${m('--spacing-xxs')} 0;
  }
`;

export const footerItemCss = css`
  color: ${m('--fg-color')};
  font-weight: 400;
  text-decoration: none;
  font-size: 14px;
  white-space: pre-wrap;

  @media (hover: hover) {
    &:hover {
      text-decoration: underline;
      text-decoration-thickness: ${m('--spacing-xxxs')};
      text-underline-offset: ${m('--spacing-xxxs')};
    }
  }

  ${nonMobileMediaQuery} {
    margin: 0;
    margin-inline-end: ${m('--spacing-s')};
  }
`;
