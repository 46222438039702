import type { StatSubtopic as StatSubtopicType } from '@snapchat/mw-contentful-schema';
import { StatSubtopic as StatSubtopicSDS } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';

import type { RichText } from '../../types/RichText';
import { renderRichTextMultiLineWithMarkings } from '../../utils/renderText/renderRichText';

export const StatSubtopic: FC<StatSubtopicType> = ({ stat = '', subtitle }) => {
  return (
    <StatSubtopicSDS
      stat={stat}
      subtitle={renderRichTextMultiLineWithMarkings(subtitle as RichText)}
    />
  );
};
