import { cx } from '@emotion/css';
import clamp from 'lodash-es/clamp';
import inRange from 'lodash-es/inRange';
import type React from 'react';
import type { ReactElement, TransitionEvent } from 'react';
import { Children, cloneElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { mobileMaxWidth } from '../../constants/layout';
import { useOnScreen } from '../../hooks/useOnScreen';
import { MotifComponent, useMotifStyles } from '../../motif';
import { isSSR } from '../../utils/environment';
import { useWindowFocused } from '../../utils/useWindowFocused';
import { useWindowSize } from '../../utils/useWindowSize';
import {
  carouselAnimationDuration,
  carouselCenteredCss,
  carouselContainerCss,
  carouselContentCss,
  carouselContentWrapperCss,
  carouselFillSlidesCss,
  carouselGapCss,
  carouselSingleViewDefaultTransformCss,
  carouselSlideCss,
  withDotsCss,
} from './CarouselV3.styles';
import { Dots } from './Dots';
import { getValueFromDirection, modulo, shortestPath, useDrag } from './utils';

/**
 * Classnames added to outermost HTML element based on component behavior.
 *
 * Used to allow consumers to customize styles for various use cases.
 */
export enum CarouselFeatureClass {
  /** Classname added to outermost HTML element when Carousel contents overflow the container. */
  HasOverflow = 'sdsm-carousel-overflow',
  /**
   * Classname added to outermost HTML element when Carousel is rendering as a multiple view
   * carousel.
   */
  MultiView = 'sdsm-carousel-multi-view',
  /** Classname added to outermost HTML element when Carousel is rendering as a single view carousel. */
  SingleView = 'sdsm-carousel-single-view',
}

/**
 * Props for a Carousel component.
 *
 * @property {React.ReactNode} children - The content to be displayed within the carousel.
 * @property {boolean} [autoPlay] - (Optional) Set to `true` to enable automatic playback of the
 *   carousel.
 * @property {number} [autoPlaySpeed] - (Optional) The speed at which the carousel should auto-play
 *   (in milliseconds).
 * @property {boolean} [isRtl] - (Optional) Specifies whether the carousel should display
 *   right-to-left (RTL) direction.
 * @property {boolean} [isSingleView] - (Optional) Indicates if the carousel should behave as a
 *   single view carousel.
 * @interface CarouselV3Props
 */
export interface CarouselV3Props {
  children: React.ReactNode;
  autoPlay?: boolean;
  autoPlaySpeed?: number;
  isRtl?: boolean;
  isSingleView?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ChildNodeType = ReactElement<any, any>;

/**
 * Carousel V3 component.
 *
 * A carousel that renders either CarouselCardItem or CarouselTextItem.
 */
export const CarouselV3 = ({
  children,
  isSingleView = false,
  isRtl = false,
  autoPlay = true,
  autoPlaySpeed = 3000,
}: CarouselV3Props): JSX.Element => {
  useMotifStyles(MotifComponent.CAROUSEL);
  const carouselContentWrapperRef = useRef<HTMLDivElement>(null);
  const [carouselContent, setCarouselContent] = useState<HTMLDivElement | null>(null);
  const carouselContentRefCallback = useCallback((node: HTMLDivElement) => {
    setCarouselContent(node);
  }, []);

  const fadeOutTimeouts = useRef<ReturnType<typeof setTimeout>[]>([]);
  const autoplayInterval = useRef<ReturnType<typeof setInterval>>();
  const enableTransition = useRef(false);
  const [isPaused, setIsPaused] = useState(false);
  const isOnScreen = useOnScreen(carouselContentWrapperRef);
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const { isFocused } = useWindowFocused(isOnScreen);

  const [tileSize, setTileSize] = useState<{ width: number; gap: number }>({ width: 0, gap: 0 });

  const childElements = Children.toArray(children) as ChildNodeType[];
  const cardsLength = childElements.length;

  const { width: windowWidth } = useWindowSize();

  // This useMemo is used to calculate how many cards are visible on screen
  const visibleCount = useMemo(() => {
    const carouselContentWidth = carouselContent?.clientWidth;

    if (!carouselContentWidth) {
      return 0;
    }
    const cardWidth = tileSize.width + tileSize.gap;

    if (!cardWidth) {
      return 0;
    }

    if (isSingleView) {
      return 1;
    }

    const numberOfCards = Math.ceil(carouselContentWidth / cardWidth);

    return numberOfCards;
    // Directly using "carouselContent.clientWidth" as a dependency will cause a performance hit
    // since referencing the "clientWidth" property can cause layout/reflow on the browser (see
    // https://gist.github.com/paulirish/5d52fb081b3570c81e3a). Instead, we can use the throttled
    // "windowWidth" as a trigger to recalculate this memo on window resize.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowWidth, carouselContent, isSingleView, tileSize.gap, tileSize.width]);

  // This useMemo is used determine wether we need to render a carousel for the amount of cards
  const isCarousel = useMemo(() => cardsLength >= visibleCount, [cardsLength, visibleCount]);

  // This useMemo is used to predict how many cards are visible on screen
  const duplicateCount = useMemo(() => {
    if (!isCarousel) {
      return 0;
    }

    return isSingleView ? 1 : Math.max(0, visibleCount * 2 - 1);
  }, [isCarousel, isSingleView, visibleCount]);

  // This useEffect is used to measure the tiles and gap.
  useEffect(() => {
    if (!carouselContent) {
      return;
    }
    const tile = carouselContent?.firstChild as HTMLDivElement;

    const width = tile?.clientWidth;
    const wrapperStyles = window.getComputedStyle(carouselContent);
    const gapCss = wrapperStyles.getPropertyValue('gap');
    const gapInt = Number.parseInt(gapCss);
    const gap = Number.isNaN(gapInt) ? 0 : gapInt;
    setTileSize({ width, gap });
  }, [windowWidth, carouselContent, children, isSingleView, cardsLength]);

  // This useEffect is used to handle animations when in single View mode
  useEffect(() => {
    if (!isSingleView || !carouselContent?.children?.length) {
      return;
    }

    fadeOutTimeouts.current?.forEach(timeoutID => clearTimeout(timeoutID));

    fadeOutTimeouts.current = Array.from(carouselContent.children).map((slide, index) => {
      // setTimeout here to delay the fadeIn animation, otherwise the animation happens before the card is visible
      const isCurrent = index === duplicateCount + currentIndex;

      return setTimeout(
        () => {
          slide.classList.toggle('fadeOut', !isCurrent);
        },
        isCurrent ? carouselAnimationDuration / 2 : 0
      );
    });
  }, [carouselContent?.children, currentIndex, isSingleView, duplicateCount]);

  // Offset is the translateX for the carousel.
  const currentOffset = useMemo(
    () =>
      (currentIndex + duplicateCount) * tileSize.width +
      (currentIndex + duplicateCount - 0.5) * tileSize.gap,
    [currentIndex, tileSize, duplicateCount]
  );

  // Calculate if any card is being cut off by the container and apply a gradient mask to soften the edge
  const showMask = useMemo(() => {
    const carouselContentWidth = carouselContent?.clientWidth ?? 0;
    const cardWidth = tileSize.width + tileSize.gap;

    /* We want to add one gap width to the container width because we will divide by the card width which
       includes one gap width. However, the content container already has half a gap width of space at the
       start, so we will add just another half a gap width here to make up the difference.
    */
    const carouselContentWidthWithExtraGap = carouselContentWidth + tileSize.gap / 2;

    // Calculate the space remaining after each full card (plus gap) is fully visible
    const remainingSpace = carouselContentWidthWithExtraGap % cardWidth;

    /* Only show the mask if the remaining space after the fully visible cards is greater than the gap size.
       If the remaining space is:
       - <= gap size --> only gap is being cut off by the container so no mask necessary
       - > gap size --> some of the next card is being cut off by the container so activate the mask
    */
    return remainingSpace > tileSize.gap;
  }, [carouselContent?.clientWidth, tileSize.width, tileSize.gap]);

  const shiftItems = useCallback(
    (numberOfItemsToShift: number) => {
      enableTransition.current = true;

      setCurrentIndex(
        currentIndex => currentIndex + (isRtl ? -numberOfItemsToShift : numberOfItemsToShift)
      );
    },
    [isRtl, setCurrentIndex]
  );

  // This useEffect is used to setup up the autoplay behavior.
  useEffect(() => {
    if (
      isCarousel &&
      !autoplayInterval.current &&
      autoPlay &&
      !isPaused &&
      isOnScreen &&
      isFocused
    ) {
      // TODO: Consider shifting immediately to make the acknowledgement of interaction.
      // I.e. add: setTimeout(() => shiftItems(1), 1e2);
      // This does make it shift whenever the user scrolls to the carousel which is a
      // strong movement and attention grabber.

      autoplayInterval.current = setInterval(() => {
        shiftItems(1);
      }, autoPlaySpeed);
    }

    return () => {
      clearInterval(autoplayInterval.current);
      autoplayInterval.current = undefined;
    };
  }, [
    autoplayInterval,
    isPaused,
    isOnScreen,
    isFocused,
    shiftItems,
    autoPlay,
    autoPlaySpeed,
    isCarousel,
  ]);

  const handleKeyUp = useCallback(
    (event: { keyCode: number }) => {
      if (!isOnScreen) {
        return;
      }

      // 37 - Keyboard Left Key Code
      if (event.keyCode === 37) {
        shiftItems(-1);
        return;
      }

      // 39 - Keyboard Right Key Code
      if (event.keyCode === 39) {
        shiftItems(1);
      }
    },
    [isOnScreen, shiftItems]
  );

  // This function handles user clicks on navigation dots and calculates the shortest path to navigate from
  // the current index to the target index, considering whether to move left or right,
  // and then performs the navigation action.
  const onDotClick = useCallback(
    (targetIndex: number) => {
      if (targetIndex === currentIndex || (windowWidth && windowWidth <= mobileMaxWidth)) {
        return;
      }

      enableTransition.current = true;

      const finalDelta = shortestPath(currentIndex, targetIndex, cardsLength);

      // Ensure that the navigation wraps around the list if needed.
      const newIndex = currentIndex + finalDelta;

      setCurrentIndex(newIndex);
    },
    [cardsLength, currentIndex, windowWidth]
  );

  const dragHandlers = useDrag({
    dragStart: () => {},
    dragMove: (delta, event) => {
      event?.preventDefault();
      carouselContentWrapperRef.current?.classList.add('dragging');
      // The following logic differs from LTR from RTL
      const xPosition = currentOffset + getValueFromDirection(delta, !isRtl);

      carouselContent!.style.transform = `translateX(${getValueFromDirection(xPosition, isRtl)}px)`;
    },
    dragEnd: delta => {
      carouselContentWrapperRef.current?.classList.remove('dragging');

      const threshold = isSingleView ? tileSize.width / 2 : tileSize.width;

      // We will snap to the next/previous item if we swipe/drag at least 50% of the item width
      const numberOfItemsToShift = Math.round(delta / threshold);

      enableTransition.current = true;

      // I'm not sure why, but delta and threshold can be 0, resulting in 'numberOfItemsToShift' being NaN.
      if (numberOfItemsToShift === 0 || Number.isNaN(numberOfItemsToShift)) {
        carouselContent!.style.transform = `translateX(${getValueFromDirection(
          currentOffset,
          isRtl
        )}px)`;

        return;
      }

      // If 'numberOfItemsToShift' arrives here as NaN, we will set the currentIndex (in shiftItems) to NaN.
      // This will lead to nasty bugs.
      const clampedSteps = clamp(numberOfItemsToShift, -duplicateCount, duplicateCount);
      shiftItems(clampedSteps);
    },
  });

  // TODO: Consider only pausing for a period of time, i.e. 5 seconds, and then resuming again
  // unless the user moves the mouse. This would make it so that the carousel doesn't stop
  // working when a user parks their mouse or gesture pointer on the carousel.
  const handleMouseEnter = useCallback(() => setIsPaused(true), [setIsPaused]);

  const handleMouseLeave = useCallback(() => {
    dragHandlers.handleDragEnd();
    setIsPaused(false);
  }, [dragHandlers, setIsPaused]);

  const handleMouseUp = useCallback(() => {
    dragHandlers.handleDragEnd();
  }, [dragHandlers]);

  // Checks if we need to change the slides position to simulate infinite loops
  const resetPosition = useCallback(() => {
    // calculate the index within the visible range to simulate infinite scroll
    const newIndex = (currentIndex + cardsLength) % cardsLength;

    const needsReset = newIndex !== currentIndex;

    if (needsReset) {
      enableTransition.current = false;
      setCurrentIndex(newIndex);
    }
  }, [currentIndex, cardsLength]);

  const handleTransitionEnd = (e: TransitionEvent<HTMLDivElement>) => {
    if (carouselContent !== e.target) {
      return;
    }
    dragHandlers.resetDrag();
    resetPosition();
  };

  const carouselContentStyle =
    isCarousel && !isSSR()
      ? {
          transform: `translateX(${getValueFromDirection(currentOffset, isRtl)}px)`,
          transition: !enableTransition.current ? 'none' : undefined,
        }
      : undefined;

  const renderDots = isCarousel && !!cardsLength;

  const preceding = Array.from(
    { length: duplicateCount },
    (_, index) => childElements[modulo(cardsLength - index - 1, cardsLength)] as ChildNodeType
  );

  const following = Array.from(
    { length: duplicateCount },
    (_, index) => childElements[modulo(index, cardsLength)] as ChildNodeType
  );

  const cardIsVisible = (index: number) => {
    return inRange(index, currentIndex, currentIndex + visibleCount);
  };

  const cardShouldLoad = (index: number) => {
    return inRange(index, currentIndex - 2, currentIndex + visibleCount + 2);
  };

  return (
    <div
      data-test-id="sdsm-carousel"
      className={cx(carouselContainerCss, MotifComponent.CAROUSEL, {
        /* Behavior classnames - used to allow consumers to customize styles for various use cases. */
        [CarouselFeatureClass.SingleView]: isSingleView,
        [CarouselFeatureClass.MultiView]: !isSingleView,
        [CarouselFeatureClass.HasOverflow]: showMask && !isSingleView,
        /* TODO: add with emotion class for motif integrated overflow style(s) once they have been added. */
        [withDotsCss]: renderDots,
      })}
      onMouseEnter={isCarousel ? handleMouseEnter : undefined}
      onMouseLeave={isCarousel ? handleMouseLeave : undefined}
      onMouseUp={isCarousel ? handleMouseUp : undefined}
      onKeyUp={isCarousel ? handleKeyUp : undefined}
      tabIndex={-1}
    >
      <div
        className={carouselContentWrapperCss}
        onTouchStart={isCarousel ? dragHandlers.handleDragStart : undefined}
        onMouseDown={isCarousel ? dragHandlers.handleDragStart : undefined}
        onTouchMove={isCarousel ? dragHandlers.handleDragMove : undefined}
        onMouseMove={isCarousel ? dragHandlers.handleDragMove : undefined}
        onTouchEnd={isCarousel ? dragHandlers.handleDragEnd : undefined}
        onMouseUp={isCarousel ? dragHandlers.handleDragEnd : undefined}
        ref={carouselContentWrapperRef}
        tabIndex={-1}
      >
        <div
          className={cx(carouselContentCss, {
            [carouselCenteredCss]: !isCarousel,
            [carouselSingleViewDefaultTransformCss]: isSingleView,
            [carouselGapCss]: !isSingleView,
          })}
          style={carouselContentStyle}
          onTransitionEnd={handleTransitionEnd}
          ref={carouselContentRefCallback}
          tabIndex={-1}
        >
          {preceding.map((child, idx) => (
            <div
              key={`initial-duplicated-${idx}`}
              className={cx(carouselSlideCss, {
                [carouselFillSlidesCss]: isSingleView,
                fadeOut: isSingleView,
                animate: enableTransition.current,
              })}
              aria-hidden={true}
            >
              {cloneElement(child, {
                shouldLoad: cardShouldLoad(idx - preceding.length),
                isVisible: cardIsVisible(idx - preceding.length),
                isSingleView,
              })}
            </div>
          ))}

          {childElements.map((child, idx) => (
            <div
              key={`carousel-item-${idx}`}
              className={cx(carouselSlideCss, {
                [carouselFillSlidesCss]: isSingleView,
                fadeOut: isSingleView && idx !== currentIndex,
                animate: enableTransition.current,
              })}
            >
              {cloneElement(child, {
                shouldLoad: cardShouldLoad(idx),
                isVisible: cardIsVisible(idx),
                isSingleView,
              })}
            </div>
          ))}

          {following.map((child, idx) => (
            <div
              key={`final-duplicated-${idx}`}
              className={cx(carouselSlideCss, {
                [carouselFillSlidesCss]: isSingleView,
                fadeOut: isSingleView,
                animate: enableTransition.current,
              })}
              aria-hidden={true}
            >
              {cloneElement(child, {
                shouldLoad: cardShouldLoad(idx + childElements.length),
                isVisible: cardIsVisible(idx + childElements.length),
                isSingleView,
              })}
            </div>
          ))}
        </div>
      </div>

      {renderDots && (
        <Dots currentIndex={currentIndex} length={cardsLength} onDotClick={onDotClick} />
      )}
    </div>
  );
};
