export type ImageFormat = 'jpg' | 'png' | 'webp' | 'avif';

export type ImageFit = 'pad' | 'fill' | 'scale' | 'crop' | 'thumb';

export type ImageResizeFocus =
  | 'bottom_left'
  | 'bottom_right'
  | 'bottom'
  | 'center'
  | 'faces'
  | 'left'
  | 'right'
  | 'top_left'
  | 'top_right'
  | 'top';

/**
 * Settings object to help interface with Contentful Image API:
 * https://www.contentful.com/developers/docs/references/images-api/
 */
export interface ContentfulImageApiParams {
  progressive?: boolean;
  width?: number;
  height?: number;
  quality?: number;
  format?: ImageFormat;
  fit?: ImageFit;
  resizeFocus?: ImageResizeFocus;
}

/**
 * See
 * https://www.contentful.com/developers/docs/references/images-api/#/reference/resizing-&-cropping/specify-width-&-height
 */
const imgMaxWidth = 4000;

const imgMaxHeight = 4000;

type GetImageUrl = (args: {
  // TODO: Make this optional.
  imageUrl: string;
  settings?: ContentfulImageApiParams;
  currentUrl?: URL;
}) => string;

/**
 * Used to get an image url from Contentful abiding by the Images API:
 * https://www.contentful.com/developers/docs/references/images-api/ This will not work for images
 * not from Contentful (unless they also follow the same pattern as Contentful's Image API...)
 *
 * @returns Url as a string. Defaults to '' if no imageUrl is provided.
 *
 *   TODO: Consider renaming to "tryGetImageUrl".
 */
export const getImageUrl: GetImageUrl = ({ imageUrl, settings, currentUrl }) => {
  if (!settings || !imageUrl) {
    return imageUrl ?? '';
  }

  const { progressive, width, height, format, quality = 40, fit } = settings;

  try {
    const uri = new URL(imageUrl, currentUrl);

    if (format) {
      uri.searchParams.set('fm', format);
    }

    if (fit) {
      uri.searchParams.set('fit', fit);
    }

    if (quality > 0 && quality <= 100) {
      uri.searchParams.set('q', String(quality));
    } else {
      uri.searchParams.set('q', String(40));
    }

    if (progressive) {
      uri.searchParams.set('fl', 'progressive');
    }

    if (width && 0 < width && width < imgMaxWidth) {
      uri.searchParams.set('w', String(width));
    }

    if (height && 0 < height && height < imgMaxHeight) {
      uri.searchParams.set('h', String(height));
    }

    return uri.href;
  } catch (uriError) {
    console.error('Error parsing URL', imageUrl, uriError);
    return imageUrl;
  }
};
