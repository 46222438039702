import { css } from '@emotion/css';

import { Gray } from '../../../constants';
import { m } from '../../../motif';

export const minHeightVariable = '--item-min-height';
export const progressIndicatorWidth = '32px'; // Value set by design

export const accordionItemAlignStartCss = css`
  text-align: start;
`;

export const accordionItemAlignCenterCss = css`
  text-align: center;
`;

export const accordionItemAlignEndCss = css`
  text-align: end;
`;

export const hiddenAccordionItemCss = css`
  display: none;
`;

export const fadeInAnimationCss = css`
  animation: fade-in 1s;

  @keyframes fade-in {
    from {
      opacity: 0.4;
    }
    to {
      opacity: 1;
    }
  }
`;

export const accordionItemContainerCss = css`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: ${m('--spacing-l')};
  margin-block: ${m('--spacing-m')};
  min-height: var(${minHeightVariable});

  &.dragging {
    a {
      pointer-events: none;
    }
  }
`;

export const mediaContainerCss = css`
  aspect-ratio: 3/2;
  position: relative;
  width: 100%;
`;

export const imageCss = css`
  height: 100%;
  left: 50%;
  object-fit: contain;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 0.2s linear;
  width: 100%;
`;

export const accordionControlsContainerCss = css`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  gap: ${m('--spacing-xs')};
  justify-content: center;
  overflow: hidden;
`;

export const navDotsContainerCss = css`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const progressIndicatorContainerCss = css`
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: ${m('--spacing-xs')};
  justify-content: center;
  padding: 0 ${m('--spacing-s')};
`;

export const progressIndicatorCss = css`
  background: ${Gray.V250};
  border-radius: ${m('--spacing-xxs')};
  height: ${m('--spacing-xs')};
  inset-block-start: 0;
  inset-inline-start: 0;
  overflow: hidden;
  width: ${progressIndicatorWidth};
`;

export const progressIndicatorBarCss = css`
  background: ${m('--fg-color')};
  border-radius: ${m('--spacing-xxs')};
  height: 100%;
  transform-origin: center left;
  width: 100%;
`;

export const dotContainerCss = css`
  align-items: center;
  display: flex;
  gap: ${m('--spacing-xs')};
  justify-content: center;
  padding: ${m('--spacing-m')} ${m('--spacing-s')};
`;

export const dotCss = css`
  align-items: center;
  background: ${Gray.V250};
  border-radius: ${m('--spacing-xxs')};
  display: flex;
  height: ${m('--spacing-xs')};
  justify-content: flex-end;
  width: ${m('--spacing-xs')};
`;

export const navButtonCss = css`
  align-items: center;
  background: ${Gray.V200};
  border-radius: 80px;
  cursor: pointer;
  display: flex;
  gap: ${m('--spacing-xs')};
  height: ${m('--spacing-xxl')};
  justify-content: center;
  padding: ${m('--spacing-s')};
  width: ${m('--spacing-xxl')};

  &:hover {
    background: ${Gray.V250};
  }
`;

export const contentContainerCss = css`
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: ${m('--spacing-m')};
`;

export const contentTitleCss = css`
  align-self: stretch;

  && {
    font-size: ${m('--h6-mobile-font-size')};
    font-weight: ${m('--h6-mobile-font-weight')};
    line-height: 120%;
    margin: 0;
    padding: 0;
  }
`;

export const contentBodyCss = css`
  align-self: stretch;
  font-size: ${m('--p2-mobile-font-size')};
  font-weight: ${m('--p2-mobile-font-weight')};
  padding-bottom: auto;
`;
