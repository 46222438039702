const getTokens = (baseUrl: string, url: string) => {
  // check if url is a valid asset url
  if (!url.startsWith(baseUrl)) return {};

  // remove "base" from url
  const withoutBase = url.replace(baseUrl, '');

  // remove trailing file extension
  const withoutExtension = withoutBase.match(/(.*)\.[^.]+$/)?.[1];

  // extract tokens
  const [spaceId, assetId, assetHash, fileName] = withoutExtension?.split('/') ?? [];

  return {
    spaceId,
    assetId,
    assetHash,
    fileName,
  };
};

/**
 * Extracts the space ID, asset ID, asset hash, and file name from an asset URL formatted as follow:
 *
 * https://videos.ctfassets.net/space-id/asset-id/asset-hash/file-name.file-extension
 */
export const getTokensFromAssetUrl = (assetUrl: string) =>
  getTokens('https://videos.ctfassets.net/', assetUrl);

/**
 * Extracts the asset hash from a VOD URL formatted as follow:
 *
 * https://web-platform.snap.com/vod/space-id/asset-id/asset-hash/file-name.m3u8
 */
export const getTokensFromVodUrl = (vodUrl: string) =>
  getTokens('https://web-platform.snap.com/vod/', vodUrl);
