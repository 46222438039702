import { MultiValuePropBlock as MultiValuePropBlockSDS } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';

import {
  getDprSrcSetSettingsByHeight,
  useContentfulImages,
} from '../../../../contentful-client/src';
import { combineImageSources } from '../../utils/combineImageSources';
import {
  renderRichTextMultiLineWithMarkings,
  renderRichTextWithElementsNoHeadings,
} from '../../utils/renderText/renderRichText';
import { CallToAction } from '../CallToAction';
import { ImageSubtopic } from './ImageSubtopic';
import { StatSubtopic } from './StatSubtopic';
import { TextSubtopic } from './TextSubtopic';
import type { MultiValuePropBlockProps } from './types';

// Default Image height values for each available Aspect Ratio
const DefaultImageHeights = {
  '3:2': 315,
  '9:16': 656,
  '1:1': 472,
  '16:9': 265,
};

const DefaultMobileImageHeights = {
  '3:2': 180,
  '9:16': 400,
  '1:1': 271,
  '16:9': 152,
};

export const MultiValuePropBlock: FC<MultiValuePropBlockProps> = props => {
  const { getImageSources } = useContentfulImages();

  const {
    backgroundColor,
    title,
    multiValuePropBlockSubtitle,
    body,
    subtopicsCollection,
    callsToActionCollection,
    mediaCollection,
    desktopMediaAspectRatio,
    mobileMediaAspectRatio,
    mediaDirection,
    autoplayCarousel,
    fullHeight,
  } = props;

  const callsToAction = callsToActionCollection?.items?.map(item => (
    <CallToAction key={item.sys.id} {...item} />
  ));

  const mediaSources = mediaCollection?.items?.map(item => {
    if (item.__typename === 'Image') {
      return {
        type: item.__typename,
        altText: item.media.description,
        sources: combineImageSources({
          desktop: getImageSources(
            item.media.url,
            getDprSrcSetSettingsByHeight(
              DefaultImageHeights[desktopMediaAspectRatio],
              item.media.height ?? 0
            )
          ),
          mobile: getImageSources(
            item.mobileMedia?.url,
            getDprSrcSetSettingsByHeight(
              DefaultMobileImageHeights[mobileMediaAspectRatio],
              item.mobileMedia?.height ?? 0
            )
          ),
        }),
      };
    }

    if (item.__typename === 'Video') {
      return {
        type: item.__typename,
        autoPlay: !!item.autoPlay,
        videoSource: item.media.url,
        mobileVideoSource: item?.mobileMedia?.url,
        sourceType: item.media.contentType,
      };
    }

    return;
  });

  const subtopics = subtopicsCollection.items;

  return (
    <MultiValuePropBlockSDS
      backgroundColor={backgroundColor}
      title={renderRichTextMultiLineWithMarkings(title)}
      subtitle={multiValuePropBlockSubtitle}
      body={renderRichTextWithElementsNoHeadings(body)}
      autoplayCarousel={autoplayCarousel}
      callsToAction={callsToAction?.length ? callsToAction : undefined}
      mediaSources={mediaSources}
      desktopMediaAspectRatio={desktopMediaAspectRatio}
      mobileMediaAspectRatio={mobileMediaAspectRatio}
      mediaDirection={mediaDirection}
      fullHeight={fullHeight}
    >
      {subtopics.map(subtopic => {
        if (subtopic.__typename === 'ImageSubtopic') {
          return <ImageSubtopic key={subtopic.sys.id} {...subtopic} />;
        }

        if (subtopic.__typename === 'StatSubtopic') {
          return <StatSubtopic key={subtopic.sys.id} {...subtopic} />;
        }

        if (subtopic.__typename === 'TextSubtopic') {
          return <TextSubtopic key={subtopic.sys.id} {...subtopic} />;
        }

        return null;
      })}
    </MultiValuePropBlockSDS>
  );
};
