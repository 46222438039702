import { css } from '@emotion/css';

import { mobileMediaQuery } from '../../constants';
import { ZIndex } from '../../constants/zIndex';
import { m } from '../../motif';

export const navCss = css`
  align-items: center;
  background-color: ${m('--bg-color')};
  box-shadow: 0 16px 32px -16px rgba(0, 0, 0, 0.08); /* +16px vertical and -16px spread to prevent showing above the nav */
  color: white;
  display: flex;
  height: 56px;
  justify-content: center;
  overflow: hidden;
  position: sticky;
  top: 125px;
  width: 100%;
  z-index: ${ZIndex.SUB_NAVIGATION};

  ${mobileMediaQuery} {
    justify-content: flex-start;
  }

  &::after {
    background: linear-gradient(to right, transparent 0, ${m('--bg-color')} 100%);
    content: '';
    display: block;
    height: 100%;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    width: ${m('--spacing-xl')};

    [dir='rtl'] & {
      background: linear-gradient(to left, transparent 0, ${m('--bg-color')} 100%);
      left: 0;
      right: auto;
    }
  }
`;

export const itemsWrapCss = css`
  align-items: center;
  display: flex;
  height: 100%;
  -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
  overflow-x: scroll;
  overflow-y: hidden;
  scrollbar-width: none; /* Hide scrollbar for Firefox */

  &::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome and Safari */
  }
`;

export const itemsCss = css`
  align-items: center;
  display: flex;
  gap: ${m('--spacing-xl')};
  height: 100%;
  list-style: none;
  padding-inline: ${m('--spacing-xl')};
`;

export const itemCss = css`
  align-items: center;
  display: flex;
  height: 100%;
`;

const underlineHeight = 8;

export const itemLinkCss = css`
  align-items: center;
  color: ${m('--sub-navigation-item-color')};
  display: flex;
  font-size: ${m('--action-desktop-font-size')};
  font-weight: ${m('--action-desktop-font-weight')};
  height: 100%;
  line-height: ${m('--action-desktop-font-line-height')};
  position: relative;
  text-decoration: none;
  white-space: nowrap;

  ${mobileMediaQuery} {
    font-size: ${m('--action-mobile-font-size')};
    font-weight: ${m('--action-mobile-font-weight')};
    line-height: ${m('--action-mobile-font-line-height')};
  }

  @media (hover: hover) {
    &:hover {
      color: ${m('--sub-navigation-item-hover-color')};
    }

    &:hover::after {
      transform: translateY(-${underlineHeight / 2}px);
    }
  }

  &::after {
    background-color: ${m('--sub-navigation-item-hover-decoration-color')};
    bottom: -${underlineHeight}px;
    content: '';
    height: ${underlineHeight}px;
    left: 0;
    position: absolute;
    border-radius: ${m('--border-radius-s')};
    transform: translateY(0);
    transition: all 0.15s ease;
    width: 100%;
  }

  @media (hover: hover) {
    &:hover {
      color: ${m('--sub-navigation-item-hover-color')};
    }

    &:hover::after {
      transform: translateY(-${underlineHeight / 2}px);
    }
  }
`;

export const itemLinkActiveCss = css`
  color: ${m('--sub-navigation-item-active-color')};

  &::after {
    background-color: ${m('--sub-navigation-item-active-decoration-color')};
    transform: translateY(-${underlineHeight / 2}px);
  }
`;
