import gql from 'graphql-tag';

import { fragments as imageFragments } from '../../components/Image';
import { fragments as videoFragments } from '../../components/Video';
import { contentfulSysIdFragment } from '../../utils/contentful/contentfulQuery';
import { ctaFragment } from '../CallToAction';

export const textSubtopicFragment = gql`
  fragment TextSubtopicAll on TextSubtopic {
    ...ContentfulSysId
    icon {
      ...ContentfulSysId
      icon
      media {
        ...AssetAll
      }
    }
    title
    body {
      json
    }
  }
`;

export const imageSubtopicFragment = gql`
  fragment ImageSubtopicAll on ImageSubtopic {
    ...ContentfulSysId
    image {
      ... on Image {
        ...ImageAll
      }
    }
    title
  }
`;

export const statSubtopicFragment = gql`
  fragment StatSubtopicAll on StatSubtopic {
    ...ContentfulSysId
    stat
    subtitle {
      json
    }
  }
`;

export const multiValuePropBlockFragment = gql`
  fragment MultiValuePropBlockAll on MultiValuePropBlock {
    ...ContentfulSysId
    mediaCollection {
      items {
        ... on Video {
          ...VideoAll
        }
        ... on Image {
          ...ImageAll
        }
      }
    }
    desktopMediaAspectRatio
    mobileMediaAspectRatio
    mediaDirection
    autoplayCarousel
    title {
      json
    }
    multiValuePropBlockSubtitle: subtitle
    body {
      json
    }
    subtopicsCollection {
      items {
        __typename
        ... on ImageSubtopic {
          ...ImageSubtopicAll
        }
        ... on StatSubtopic {
          ...StatSubtopicAll
        }
        ... on TextSubtopic {
          ...TextSubtopicAll
        }
      }
    }
    callsToActionCollection(limit: 2) {
      items {
        ... on CallToAction {
          ...CallToActionAll
        }
      }
    }
    backgroundColor
    fullHeight
  }
  ${contentfulSysIdFragment}
  ${ctaFragment}
  ${imageFragments.all}
  ${videoFragments.all}
  ${imageSubtopicFragment}
  ${statSubtopicFragment}
  ${textSubtopicFragment}
`;

export const multiValuePropBlockQuery = gql`
  query MultiValuePropBlockQuery($preview: Boolean!, $locale: String!, $id: String!) {
    multiValuePropBlock(preview: $preview, locale: $locale, id: $id) {
      ...MultiValuePropBlockAll
    }
  }
  ${multiValuePropBlockFragment}
`;
