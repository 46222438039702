import { Black, Gray, Plain, Yellow } from '../../constants';
import type { MotifComponent, MotifComponentProps } from '../../motif';
import { m } from '../../motif/m';

export const footerBlackBgVars: MotifComponentProps<MotifComponent.FOOTER> = {
  '--footer-bg-color': Black.V200,
  '--footer-fg-color': m('--fg-color'),
  '--footer-border-color': Black.V50,
  '--footer-divider-border-color': Black.V50,
  '--footer-bar-bg-color': Plain.BLACK,
  '--footer-bar-fg-color': m('--fg-color'),
  '--footer-bar-divider-border-color': Black.V50,
};

export const footerYellowBgVars: MotifComponentProps<MotifComponent.FOOTER> = {
  '--footer-bg-color': Yellow.V100,
  '--footer-fg-color': m('--fg-color'),
  '--footer-border-color': Black.V50,
  '--footer-divider-border-color': Black.V50,
  '--footer-bar-bg-color': Yellow.V100,
  '--footer-bar-fg-color': m('--fg-color'),
  '--footer-bar-divider-border-color': Black.V50,
};

export const footerWhiteBgVars: MotifComponentProps<MotifComponent.FOOTER> = {
  '--footer-bg-color': Plain.WHITE,
  '--footer-fg-color': m('--fg-color'),
  '--footer-border-color': Gray.V250,
  '--footer-divider-border-color': Gray.V250,
  '--footer-bar-bg-color': Gray.V100,
  '--footer-bar-fg-color': m('--fg-color'),
  '--footer-bar-divider-border-color': Gray.V100,
};

export const footerGrayBgVars: MotifComponentProps<MotifComponent.FOOTER> = {
  '--footer-bg-color': Gray.V100,
  '--footer-fg-color': m('--fg-color'),
  '--footer-border-color': Gray.V250,
  '--footer-divider-border-color': Gray.V250,
  '--footer-bar-fg-color': m('--fg-color'),
  '--footer-bar-bg-color': Gray.V100,
  '--footer-bar-divider-border-color': Gray.V250,
};
