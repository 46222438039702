import type { GetBestImgSrc } from '@snapchat/mw-contentful-client';
import { useContentfulImages } from '@snapchat/mw-contentful-client';
import type { MediaProps as MediaSDSProps } from '@snapchat/snap-design-system-marketing';
import { Media as MediaSDS } from '@snapchat/snap-design-system-marketing';
import isNil from 'lodash-es/isNil';
import type React from 'react';
import type { FC } from 'react';
import { useContext, useState } from 'react';

import type { ContentfulIdVariable } from '../../hooks/useContentfulQuery';
import { useContentfulQuery } from '../../hooks/useContentfulQuery';
import { useParseMediaContainer } from '../../hooks/useParseMediaContainer';
import { UserAction } from '../../types/events';
import { isContentfulSysProps } from '../../utils/contentful';
import { getFileInfo } from '../../utils/getFileInfo';
import { parseMedia } from '../../utils/media';
import { ConsumerContext } from '../ConsumerContextProvider';
import { query } from './query';
import type { VideoDataHandlerProps, VideoDataProps, VideoProps } from './types';
import { getVideoWatchLogger } from './utils';

/**
 * @deprecated Use useParseMediaContainer instead
 *
 *   TODO Remove this function (https://jira.sc-corp.net/browse/WEBP-11399)
 */
export const getMediaProps = (
  video: VideoDataProps,
  getBestImgSrc: GetBestImgSrc
): MediaSDSProps => {
  const { media, mobileMedia, thumbnailMedia, autoPlay = true, wrap, captions } = video;
  const { videoSource } = parseMedia(media);
  const { videoSource: mobileVideoSource } = parseMedia(mobileMedia);
  const posterSource = getBestImgSrc(video.thumbnailMedia?.url, { width: 728 });
  return {
    videoSource,
    mobileVideoSource,
    posterSource,
    showVideoControls: isNil(autoPlay) ? false : !autoPlay,
    wrap: isNil(wrap) ? undefined : wrap,
    captionsSource: captions?.url,
    // if user sets alt text for the thumbnail, use it.  Otherwise use the video description.
    altText: thumbnailMedia?.description ?? media?.description,
  };
};

export const Video: FC<VideoProps> = props => {
  const id = isContentfulSysProps(props) ? props.sys.id : undefined;
  const { data } = useContentfulQuery<VideoDataHandlerProps, ContentfulIdVariable>(query, {
    skip: !id,
    variables: { id },
  });
  const [videoPlayed, setVideoPlayed] = useState(false);
  const { getBestBgImgSrc } = useContentfulImages();

  let prevWatchEventTime = -1;
  const consumerContext = useContext(ConsumerContext);
  const { logEvent } = consumerContext;

  const {
    media: { videoSource },
  } = useParseMediaContainer(data?.video);

  if (!data) return null;
  const mediaProps = getMediaProps(data.video, getBestBgImgSrc);

  const onPlay: React.ReactEventHandler<HTMLVideoElement> = _e => {
    if (logEvent && videoSource && !videoPlayed) {
      if (data.video.autoPlay) return; // Skip for auto play videos
      const { fileName } = getFileInfo(videoSource);

      logEvent({ component: 'Video', type: UserAction.Play, label: fileName });
      setVideoPlayed(true);
    }
  };

  const onTimeUpdate = getVideoWatchLogger({
    autoPlay: data.video.autoPlay,
    // Note: should never resolve to 'unknown', implemented to resolve type error
    eventLabel: data.video.externalId ?? id ?? 'unknown',
    getPreviousWatchTime: () => {
      return prevWatchEventTime;
    },
    setPreviousWatchTime: value => {
      prevWatchEventTime = value;
    },
  });

  return (
    <MediaSDS
      onPlay={onPlay}
      onTimeUpdate={onTimeUpdate}
      dataset={props.dataset}
      {...mediaProps}
      videoSource={videoSource}
    />
  );
};

Video.displayName = 'Video';
