import { cx } from '@emotion/css';
import type { FC } from 'react';

import { m } from '../../motif/m';
import { dataSetToAttributes } from '../../utils';
import { Icon } from '../Icon';
import { testIds } from './GlobalHeader.constants';
import {
  globalHeaderCtaMobileCss,
  globalHeaderMobileBarCss,
  globalHeaderMobileCss,
  globalHeaderMobileNavButtonCss,
} from './GlobalHeader.styles';
import { testIds as navScreenTestIds } from './GlobalNavScreen/GlobalNavScreen.constants';
import type { GlobalHeaderMobileProps } from './types';

export const GlobalHeaderMobile: FC<GlobalHeaderMobileProps> = ({
  logo,
  cta,
  className,
  isExpanded,
  toggleExpanded,
  showNavScreen,
  endChildrenClassName,
  buttonAriaLabel,
  dataset,
}) => (
  <header className={cx(globalHeaderMobileCss, className)} {...dataSetToAttributes(dataset)}>
    {showNavScreen && (
      <button
        onClick={toggleExpanded}
        className={globalHeaderMobileNavButtonCss}
        data-testid={isExpanded ? navScreenTestIds.closeButton : testIds.openButton}
        aria-label={buttonAriaLabel}
      >
        {isExpanded ? (
          <Icon name="cross" size={23} fill={m('--fg-color')} />
        ) : (
          <Icon name="bars" className={globalHeaderMobileBarCss} size={30} fill={m('--fg-color')} />
        )}
      </button>
    )}
    {logo}
    <aside
      className={endChildrenClassName ?? globalHeaderCtaMobileCss}
      data-testid={testIds.endContents}
    >
      {cta}
    </aside>
  </header>
);

GlobalHeaderMobile.displayName = 'GlobalHeaderMobile';
