import { css } from '@emotion/css';

import { mobileMediaQuery } from '../../../constants';
import { m } from '../../../motif/m';
import { globalHeaderHeight } from '../../GlobalHeader/GlobalHeader.constants';

export const navigatorItemDesktopCss = css`
  align-items: center;
  color: ${m('--global-header-navigator-item-color')};
  display: flex;
  font-size: ${m('--action-desktop-font-size')};
  font-weight: 500;
  line-height: ${globalHeaderHeight}px;
  margin-left: ${m('--spacing-m')};
  margin-right: ${m('--spacing-m')};
  padding-bottom: 0;
  padding-top: 0;
  text-decoration: none;
  white-space: nowrap;

  ${mobileMediaQuery} {
    font-size: ${m('--action-mobile-font-size')};
  }

  &:hover {
    color: ${m('--global-header-navigator-item-hover-color')};
  }

  & span {
    position: relative; /* For the underline */
    overflow-y: hidden;
  }

  /** Custom underline */
  & > span::after {
    /* TODO: consolidate the rounded underline css? */
    background-color: ${m('--global-header-navigator-item-hover-color')};
    bottom: -8px;
    content: '';
    height: 8px;
    left: 0;
    position: absolute;
    border-radius: ${m('--border-radius-s')};
    transform: translateY(0);
    transition: all 0.15s ease;
    width: 100%;
  }

  &:hover > span::after {
    opacity: 0.6; /* TODO: convert to motif variable instead */
    transform: translateY(-4px);
  }

  & > span.selected {
    color: ${m('--global-header-navigator-item-active-color')};
  }

  & > span.selected::after {
    opacity: 1;
    transform: translateY(-4px);
  }
`;

export const navigatorItemDesktopClickableCss = css`
  cursor: pointer;
`;

export const navigatorItemDesktopIconCss = css`
  /**
   * use negative margin to make icon take up space same as previous
   */
  margin: calc(${m('--spacing-xxs')} * -1);
  fill: ${m('--global-header-navigator-item-color')};

  margin-left: calc(${m('--spacing-xs')} - 4px);
  *[dir='rtl'] & {
    margin-right: calc(${m('--spacing-xs')} - 4px);
  }
`;

export const navigatorItemDesktopMenuCss = css`
  background-color: ${m('--global-header-menu-bg-color')};
  border: 1px solid ${m('--global-header-menu-border-color')};
  border-bottom-left-radius: ${m('--border-radius-l')};
  border-bottom-right-radius: ${m('--border-radius-l')};
  border-top: none;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.05);
  /* Trim box shadow from the top, otherwise it will overlap the main header bar */
  clip-path: inset(0 -20px -20px -20px);
  left: 50%;
  max-width: calc(100vw - 2 * ${m('--spacing-m')});
  opacity: 0;
  padding: ${m('--spacing-l')};
  position: absolute;
  top: 100%;
  /*
    We NEED translateZ(0) otherwise the menu flickers for some reason on safari
    https://developer.apple.com/forums/thread/705172
    not official, but other people have used this workaround
  */
  transform: translate(-50%, 0) translateZ(0);
  transition: opacity 0.2s linear, visibility 0.2s linear;
  visibility: hidden;
  width: 1080px;
`;

export const navigatorItemDesktopMenu4ColCss = css`
  width: 1280px;
`;

/** Anchor menu position to parent element, aligns right edges of parent and menu. */
export const navigatorItemDesktopRightAlignedCss = css`
  position: relative;

  > div.sdsm-nav-item-menu {
    transform: unset;
    left: unset;
    right: 0;
  }
`;

/** Anchor menu position to parent element, aligns left edges of parent and menu. */
export const navigatorItemDesktopLeftAlignedCss = css`
  position: relative;

  > div.sdsm-nav-item-menu {
    transform: unset;
    left: 0;
  }
`;

export const navigatorItemDesktopMenuExpandedCss = css`
  opacity: 1;
  visibility: visible;
`;

export const navigatorItemDesktopMenuGridCss = css`
  display: grid;
  gap: ${m('--spacing-l')};
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
`;

export const navigatorItemDesktopMenuColCss = css`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
`;

export const navigatorItemDesktopMenuColWithDividerCss = css`
  &::before {
    background-color: ${m('--global-header-menu-col-divider-color')};
    bottom: 0;
    content: '';
    height: calc(100% - ${m('--spacing-m')});
    left: calc(${m('--spacing-s')} * -1);
    position: absolute;
    width: 1px;

    [dir='rtl'] & {
      left: auto;
      right: calc(${m('--spacing-s')} * -1);
    }
  }
`;

export const navigatorItemDesktopMenuColLabelCss = css`
  && {
    color: ${m('--global-header-menu-col-label-color')};
    font-size: ${m('--p3-desktop-font-size')};
    font-weight: 500;
    letter-spacing: -0.01em;
    line-height: ${m('--p3-desktop-font-line-height')};
    margin: ${m('--spacing-m')};
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
    white-space: nowrap;
  }
`;
