import { css } from '@emotion/css';

import { mobileMediaQuery } from '../../../constants';
import { m } from '../../../motif/m';
import { primitiveElementClassNames } from '../Primitives.styles';

export const tableWithHeadersClassName = 'table-with-headers';
export const columnCountVariable = '--column-count';

export const tableWrapperCss = css`
  background-color: ${m('--table-bg-color')};
  border-radius: ${m('--border-radius-s')};
  margin-bottom: ${m('--spacing-m')};
  overflow-x: auto;

  /* Paragraph margins in table cells look like extra padding, so we remove them for tables */
  .${primitiveElementClassNames.p} {
    margin-bottom: 0;
    overflow-wrap: break-word;
  }
`;

/**
 * Base CSS for table. Note that grid-template-columns is calculated and set inline to avoid
 * performance hit from using emotion/css in the render path since it is dependent on the number of
 * columns in the table which we can only determine at runtime.
 */
export const tableCss = css`
  /* Allow table to scroll horizontally on smaller viewpoints */
  border-collapse: collapse;
  display: grid;
  grid-template-columns: repeat(
    var(${columnCountVariable}),
    minmax(${m('--table-cell-min-width')}, 1fr)
  );
  grid-row-gap: ${m('--table-desktop-row-gap')};
  min-width: ${m('--table-cell-min-width')};
  overflow-x: auto;
  width: 100%;
  padding: ${m('--spacing-m')} 0;

  div[role='cell'] {
    padding: ${m('--table-cell-desktop-padding')};
  }

  &.${tableWithHeadersClassName} {
    padding: unset;
    grid-template-columns: repeat(
      var(${columnCountVariable}),
      minmax(${m('--table-cell-min-width')}, 1fr)
    );

    div[role='cell'] {
      border-bottom: 1px solid ${m('--table-border-color')};
      padding: ${m('--spacing-m')};
    }
  }
`;

export const overflowOnMobileCss = css`
  ${mobileMediaQuery} {
    overflow-x: scroll;
  }
`;

export const collapsedMobileTableCss = css`
  grid-template-columns:
    minmax(${m('--table-cell-min-width')}, ${m('--table-first-column-max-width')})
    repeat(calc(var(${columnCountVariable}) - 1), minmax(${m('--table-cell-min-width')}, 1fr));

  /** mobile media query single column layout */
  div[role='cell'] {
    padding: ${m('--table-cell-desktop-padding')};
  }

  ${mobileMediaQuery} {
    grid-row-gap: ${m('--table-mobile-row-gap')};
    grid-auto-flow: row;
    grid-template-columns: minmax(0, 1fr);
    div[role='row'] {
      display: block;
    }
    div[role='cell'] {
      padding: ${m('--table-cell-mobile-padding')};
    }
  }
`;

export const tableCellCss = css`
  background-color: ${m('--table-cell-body-bg-color')};
  color: ${m('--table-cell-fg-color')};
`;

export const tableHeaderCss = css`
  background-color: ${m('--table-cell-header-bg-color')};
  border-bottom: 1px solid ${m('--table-border-color')};
  color: ${m('--table-cell-fg-color')};
  padding: ${m('--spacing-m')};
`;

export const tableRowCss = css`
  display: contents;
`;
