import { css } from '@emotion/css';

import {
  extraLargeDesktopMediaQuery,
  largeDesktopMediaQuery,
  nonMobileMediaQuery,
} from '../../constants';
import { m } from '../../motif';

// NOTE(paulomartinsbynd): these values have been decided by the design team
const summaryCardHeight = 225;
const mobileSummaryCardHeight = 180;
const dataLineHeight = 20;

export const summaryCardCss = css`
  color: ${m('--summary-card-fg-color')};
  background-color: ${m('--summary-card-bg-color')};
  border: ${m('--summary-card-border-width')} solid ${m('--summary-card-border-color')};
  border-radius: ${m('--summary-card-border-radius')};
  display: flex;
  box-shadow: ${m('--summary-card-box-shadow')};
  flex-direction: row;
  height: ${mobileSummaryCardHeight}px;
  overflow: hidden;
  transition: background-color 0.2s linear, border-color 0.2s linear, box-shadow 0.2s linear;
  will-change: filter;

  ${nonMobileMediaQuery} {
    height: ${summaryCardHeight}px;
  }

  :hover {
    background-color: ${m('--summary-card-hover-bg-color')};
    border-color: ${m('--summary-card-hover-border-color')};
    box-shadow: ${m('--summary-card-hover-box-shadow')};
  }
`;

export const summaryCardRowCss = css`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const summaryCardImageCss = css`
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  min-width: 101px;
  width: 101px;

  video {
    border-radius: 0;
    height: ${mobileSummaryCardHeight}px;
    width: 100%;

    ${nonMobileMediaQuery} {
      height: ${summaryCardHeight}px;
    }
  }

  ${largeDesktopMediaQuery} {
    min-width: 126px;
    width: 126px;
  }

  ${extraLargeDesktopMediaQuery} {
    min-width: 134px;
    width: 134px;
  }
`;

export const summaryCardTitleCss = css`
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  font-weight: 500;
  margin-bottom: ${m('--spacing-xxs')};
  overflow: hidden;
  word-break: break-word;
  color: ${m('--summary-card-title-color')};

  ${largeDesktopMediaQuery} {
    margin-bottom: ${m('--spacing-xs')};
  }
`;
export const summaryCardTitleNoDateCss = css`
  /* NOTE(dvazbynd): margin-block-start should match line-height of summaryCardDateCss */
  margin-block-start: ${dataLineHeight}px;
`;

export const summaryCardTitleNoMediaNoDescriptionCss = css`
  -webkit-line-clamp: 4;
  font-size: 18px;
  line-height: 26px;

  ${nonMobileMediaQuery} {
    font-size: 24px;
    line-height: 34px;
  }
`;

export const summaryCardTitleMediaNoDescriptionCss = css`
  -webkit-line-clamp: 5;
  font-size: 14px;
  line-height: 20px;

  ${nonMobileMediaQuery} {
    -webkit-line-clamp: 4;
    font-size: 20px;
    line-height: 30px;
  }
`;

export const summaryCardTitleNoMediaDescriptionNoDateCss = css`
  -webkit-line-clamp: 3;
  font-size: 18px;
  line-height: 26px;

  ${nonMobileMediaQuery} {
    -webkit-line-clamp: 4;
    font-size: 20px;
    line-height: 30px;
  }
`;

export const summaryCardTitleNoMediaDescriptionCss = css`
  -webkit-line-clamp: 3;
  font-size: 18px;
  line-height: 26px;

  ${nonMobileMediaQuery} {
    -webkit-line-clamp: 3;
    font-size: 20px;
    line-height: 30px;
  }
`;

export const summaryCardTitleMediaDescriptionCss = css`
  -webkit-line-clamp: 3;
  font-size: 14px;
  line-height: 20px;

  ${nonMobileMediaQuery} {
    font-size: 20px;
    line-height: 30px;
  }
`;

export const summaryCardDateCss = css`
  font-size: 12px;
  font-weight: 500;
  line-height: ${dataLineHeight}px;
  margin-bottom: ${m('--spacing-xxs')};

  ${largeDesktopMediaQuery} {
    font-size: 14px;
    margin-bottom: ${m('--spacing-xs')};
  }
`;

export const summaryCardContainerCss = css`
  color: ${m('--summary-card-fg-color')};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: ${m('--spacing-m')};

  ${nonMobileMediaQuery} {
    padding: ${m('--spacing-l')};
  }
`;

export const summaryCardSkeletonContainerCss = css`
  justify-content: space-between;
`;

export const summaryCardDescriptionCss = css`
  -webkit-box-orient: vertical;
  color: ${m('--summary-card-description-color')};
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  overflow: hidden;
  pointer-events: none;
  word-break: break-word;

  b,
  strong,
  > * b,
  > * strong {
    font-weight: normal;
  }

  i,
  > * i {
    font-style: normal;
  }

  a,
  u,
  > * a,
  > * u {
    text-decoration: none;
    color: inherit;
  }

  ${nonMobileMediaQuery} {
    font-size: 16px;
    line-height: 26px;
  }

  > *:not(:first-child) {
    display: none;
  }
`;

export const summaryCardDescriptionMediaCss = css`
  -webkit-line-clamp: 3;

  ${nonMobileMediaQuery} {
    -webkit-line-clamp: 2;
  }
`;

export const summaryCardDescriptionNoMediaCss = css`
  -webkit-line-clamp: 2;
`;

export const summaryCardPictureCss = css`
  height: 100%;
  object-fit: cover;
  object-position: center;
  width: 100%;
`;

export const summaryCardAnchorCss = css`
  text-decoration: none;
`;

export const summaryCardSkeletonCss = css`
  background-color: rgb(232, 232, 235);
`;

export const summaryCardSkeletonGapCss = css`
  gap: 10px;
`;

export const summaryCardDashCss = css`
  border-radius: ${m('--border-radius-m')};
`;

export const summaryCardSkeletonAnimationCss = css`
  position: relative;
  overflow: hidden;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transform: translateX(-100%);
    content: '';
    background: linear-gradient(0.25turn, transparent, #fff, transparent);
    pointer-events: none;
    opacity: 0.8;
    animation: summary-card-skeleton 2s infinite;
  }

  @keyframes summary-card-skeleton {
    0% {
      transform: translateX(-100%);
    }
    30% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
`;
