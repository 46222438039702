/**
 * PubSub topics in MWP.
 *
 * UI: https://console.cloud.google.com/cloudpubsub/topic/list?project=marketing-web-platform
 *
 * These should have equivalents on staging if needed.
 */
export enum GooglePubSubTopic {
  /** Topic for cleaning up AppEngine instances. */
  APPENGINE_ADMIN = 'appengine-admin',

  /** Topic where we publish event reactions on live event (i.e. SPS). */
  BITMOJI_REACTION_STREAM = 'bitmoji-reaction-stream',

  /** Topic for publishing events for public clients to receive events via web sockets. */
  CLIENT_BROADCAST = 'client-broadcast',

  /** Topic for executing contentful maintenance tasks (like backup jobs) */
  CONTENTFUL_ADMIN_TRIGGER = 'contentful-admin-trigger',
}

/** Event types used for any messaging propagations for client susbcribers. */
export enum BroadcastEventType {
  /** Bitmoji Reaction Event (on a live video) */
  BITMOJI_REACTION = 'bitmoji-reaction-event',
  /** A contentful app is sending an event */
  CONTENTFUL_ACTION = 'contentful-action-event',
  /** A contentful object was updated */
  CONTENTFUL_WEBHOOK = 'contentful-webhook-event',
  /** Some service is requesting a video to be transcoded */
  VIDEO_TRANSCODE_REQUEST = 'video-transcode-request',
}

export enum ContentfulActions {
  /** Action for generating metadata for contentful entries */
  AI_GENERATE_METADATA = 'ai-generate-metadata',
  /** Action for fetching analytics data */
  ANALYTICS_DATA = 'analytics-data',
}
