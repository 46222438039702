import { css } from '@emotion/css';

import { largeDesktopMediaQuery, mobileMediaQuery } from '../../constants';
import { m, p2Css, p4Css } from '../../motif';
import { globalHeaderHeight } from '../GlobalHeader';

/** The following values are set by design */

/** Max width width for Content block from designs */
const maxBlockWidthPx = 864;

/** Image subtopic's width for its image */
const imageSubtopicWidthPx = 120;

/** Media width value for 9:16 aspect ratio to mimic Carousel card items */
const aspectRatio916MediaWidthPx = 369;
const mobileAspectRatio916MediaWidthPx = 225;

/** Main wrapper styles */
export const boundaryCss = css`
  display: flex;
  gap: ${m('--spacing-xl')};
  justify-content: center;
  padding: 72px ${m('--spacing-xxxxl')};
  width: 100%;

  ${largeDesktopMediaQuery} {
    align-items: center;
  }

  ${mobileMediaQuery} {
    padding: ${m('--spacing-xl')};
  }
`;

export const mainWrapperCss = css`
  background-color: ${m('--bg-color')};
  color: ${m('--fg-color')};
  display: flex;
`;

export const singleColumnCss = css`
  max-width: ${maxBlockWidthPx}px;
  width: 100%;
`;

export const reverseDirectionCss = css`
  flex-direction: row-reverse;
`;

export const fullHeightCss = css`
  min-height: calc(100vh - ${globalHeaderHeight}px);
`;

/** Content styles */
export const blockContentWrapperCss = css`
  display: flex;
  flex-direction: column;
  gap: ${m('--spacing-xxxl')};
  width: 60%;

  ${mobileMediaQuery} {
    gap: ${m('--spacing-xl')};
    width: 100%;
  }
`;

export const contentWrapperCss = css`
  display: flex;
  flex-direction: column;
  gap: ${m('--spacing-l')};
  overflow-wrap: break-word;

  ${p2Css}
`;

export const headerWrapperCss = css`
  display: flex;
  flex-direction: column;
  gap: ${m('--spacing-xs')};
`;

/** Subtopics styles */
export const subtopicsWrapperCss = css`
  display: grid;
  gap: ${m('--spacing-l')};
  grid-template-columns: repeat(2, 1fr);
  overflow-wrap: anywhere;

  ${largeDesktopMediaQuery} {
    display: flex;
  }
`;

export const subtopicCss = css`
  box-sizing: border-box;
  display: flex;
  flex: 1 0 0;

  ${p4Css}

  ${mobileMediaQuery} {
    flex-direction: column;
    gap: ${m('--spacing-xxs')};
  }
`;

export const subtopicContentWrapperCss = css`
  display: flex;
  flex-direction: column;
  gap: ${m('--spacing-xs')};
`;

export const customIconWrapperCss = css`
  display: flex;
  flex-shrink: 0;
  margin: 3px;
  pointer-events: none;
  user-select: none;
`;

export const customIconCss = css`
  height: 18px;
  width: 18px;
`;

export const subtopicSdsIconCss = css`
  fill: ${m('--fg-color')};
  flex-shrink: 0;
  margin: 3px;
`;

/** Stat subtopic styles */
export const statSubtopicCss = css`
  flex-direction: column;
`;

/** Image subtopic styles */
export const subtopicMediaWrapperCss = css`
  max-width: ${imageSubtopicWidthPx}px;
`;

export const subtopicMediaCss = css`
  aspect-ratio: 1 / 1;
`;

export const ctasContainerCss = css`
  display: flex;
  flex-wrap: wrap;
  gap: ${m('--spacing-m')};
`;

/** Media styles */
export const blockMediaWrapperCss = css`
  align-items: flex-start;
  display: flex;
  justify-content: center;
  padding-inline: 20px;
  width: 40%;

  ${largeDesktopMediaQuery} {
    align-items: center;
  }

  ${mobileMediaQuery} {
    width: 100%;
  }
`;

export const mediaWrapperCss = css`
  display: block;
  object-fit: cover;
  object-position: center;
  width: 100%;

  > picture {
    pointer-events: none;
  }
`;

export const aspectRatio916Css = css`
  aspect-ratio: 9 / 16;
  width: ${aspectRatio916MediaWidthPx}px;

  & img {
    aspect-ratio: 9 / 16;
  }
`;

export const mobileAspectRatio916Css = css`
  ${mobileMediaQuery} {
    aspect-ratio: 9 / 16;
    width: ${mobileAspectRatio916MediaWidthPx}px;

    & img {
      aspect-ratio: 9 / 16;
    }
  }
`;

export const aspectRatio169Css = css`
  aspect-ratio: 16 / 9;

  & img {
    aspect-ratio: 16 / 9;
  }
`;

export const mobileAspectRatio169Css = css`
  ${mobileMediaQuery} {
    ${aspectRatio169Css}
  }
`;

export const aspectRatio32Css = css`
  aspect-ratio: 3 / 2;

  & img {
    aspect-ratio: 3 / 2;
  }
`;

export const mobileAspectRatio32Css = css`
  ${mobileMediaQuery} {
    ${aspectRatio32Css}
  }
`;

export const aspectRatio11Css = css`
  aspect-ratio: 1 / 1;

  & img {
    aspect-ratio: 1 / 1;
  }
`;

export const mobileAspectRatio11Css = css`
  ${mobileMediaQuery} {
    ${aspectRatio11Css}
  }
`;
