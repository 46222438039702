import { css } from '@emotion/css';
import { m, nonMobileMediaQuery, p2Css } from '@snapchat/snap-design-system-marketing';

export const searchResultItemPageTitleCss = css`
  margin-block: 0 ${m('--spacing-xxs')};
`;

export const searchResultItemLinkCss = css`
  border-radius: ${m('--spacing-xxxs')};
  color: ${m('--action-default-color')};
  text-decoration: none;

  @media (hover: hover) {
    &:hover {
      color: ${m('--action-hover-color')};
      text-decoration-thickness: ${m('--spacing-xxxs')};
      text-decoration: underline;
      text-underline-offset: ${m('--spacing-xxxs')};
    }
  }
`;

export const searchResultItemDescriptionCss = css`
  ${p2Css}
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  color: ${m('--fg-color')};
  display: -webkit-box;
  hyphens: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  word-wrap: break-word;

  ${nonMobileMediaQuery} {
    -webkit-line-clamp: 2;
  }

  & strong {
    font-weight: 600;
  }
`;
