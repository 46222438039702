import { useContentfulImages } from '@snapchat/mw-contentful-client';
import { ImageButton as ImageButtonSDS } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';

import { shouldFlushLogs } from '../../helpers/getLocalPath';
import { logger } from '../../helpers/logging';
import type { ContentfulIdVariable } from '../../hooks/useContentfulQuery';
import { useContentfulQuery } from '../../hooks/useContentfulQuery';
import { UserAction } from '../../types/events';
import type { ContentfulSysId } from '../../utils/contentful/contentfulQuery';
import { parseMedia } from '../../utils/media';
import { useConsumerContext } from '../useConsumerContext';
import type { ImageButtonData } from './imageButtonQuery';
import { query } from './imageButtonQuery';

export const LazyImageButton: FC<ContentfulSysId> = props => {
  const { data } = useContentfulQuery<ImageButtonData, ContentfulIdVariable>(query, {
    variables: { id: props.sys.id },
  });
  const { getImageSources } = useContentfulImages();
  const { logEvent } = useConsumerContext();

  if (!data?.imageButton) return null;

  const { url, image, ...rest } = data.imageButton;

  const { imageSource, imageAltText } = parseMedia(image);
  const imgSrcs = getImageSources(imageSource);

  const onClickHandler = (): void => {
    logEvent?.({ type: UserAction.Click, component: 'ImageButton', url });

    if (shouldFlushLogs(url)) {
      void logger.flush();
    }
  };
  return (
    <ImageButtonSDS
      {...rest}
      url={url}
      onClick={onClickHandler}
      imgSrcs={imgSrcs}
      altText={imageAltText}
    />
  );
};
