import { css } from '@emotion/css';

import { mobileMediaQuery, Spacing } from '../../constants';
import { p1Css, p2Css } from '../../motif';
import { m } from '../../motif/m';
import { imgWidthWithIcon, labelLineHeight, tileHeight, tileWidth } from './constants';
import { TileImageFit } from './types';

export const anchorCss = css`
  text-decoration: none;
`;

export const tileCss = css`
  display: flex;
  flex-direction: column;
  width: ${tileWidth}px;
  height: ${tileHeight}px;
  border-radius: ${m('--spacing-xxs')};
  box-shadow: ${m('--box-shadow-m')};
  background-color: ${m('--tile-bg-color')};

  *[dir='rtl'] & {
    text-align: right;
  }

  &:hover {
    box-shadow: ${m('--box-shadow-l')};
  }
`;

export interface TileImageClassNameProps {
  imageSource?: string;
  imageHeight: number;
  imageFit: TileImageFit;
  imagePadding: boolean;
}

export const tileImageClassName = ({
  imageSource,
  imageHeight,
  imageFit,
  imagePadding,
}: TileImageClassNameProps): string =>
  css`
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${imageSource});
    height: ${imagePadding ? imageHeight - Spacing.SM : imageHeight}px;
    margin-top: ${imagePadding ? Spacing.SM : 0}px;
    margin-left: ${imagePadding ? Spacing.LG : 0}px;
    margin-right: ${imagePadding ? Spacing.LG : 0}px;
    background-size: ${imageFit === TileImageFit.Cover ? 'cover' : 'contain'};
    border-radius: ${imagePadding ? Spacing['2XS'] : 0}px;
    /* do the top corners of the image no matter what */
    border-top-left-radius: ${m('--spacing-xxs')};
    border-top-right-radius: ${m('--spacing-xxs')};
  `;

export const tileLabelCss = css`
  font-size: 18px;
  line-height: ${labelLineHeight}px;
  font-weight: normal;
  margin-bottom: ${m('--spacing-s')};
`;

export const tileTitleCss = css`
  padding-left: ${m('--spacing-l')};
  padding-right: ${m('--spacing-l')};
  padding-top: ${m('--spacing-s')};
  padding-bottom: ${m('--spacing-s')};
  color: ${m('--tile-fg-color')};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const innerTitleCss = css`
  ${p1Css}

  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  -webkit-box-orient: vertical;

  text-overflow: ellipsis;
  overflow: hidden;
`;

export const tileImageWithUserIconClassName = ({
  imageSource,
  imageHeight,
  imageFit,
  imagePadding,
}: TileImageClassNameProps): string =>
  css`
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${imageSource});
    height: ${imageHeight}px;
    margin-top: 0;
    margin-left: ${imagePadding ? Spacing.SM : 0}px;
    margin-right: ${imagePadding ? Spacing.SM : 0}px;
    background-size: ${imageFit === TileImageFit.Cover ? 'cover' : 'contain'}px;
    border-radius: ${m('--spacing-xs')};
    box-shadow: ${m('--box-shadow-l')};
    /* For node > picture > img */
    img {
      border-radius: ${m('--spacing-xs')};
    }
  `;

export const tileVideoWithUserIconClassName = ({
  imageHeight,
  imageFit,
  imagePadding,
}: TileImageClassNameProps): string =>
  css`
    height: ${imageHeight}px;
    margin-top: 0;
    margin-left: ${imagePadding ? Spacing.SM : 0};
    margin-right: ${imagePadding ? Spacing.SM : 0};
    background-size: ${imageFit === TileImageFit.Cover ? 'cover' : 'contain'};
    border-radius: ${m('--spacing-xs')};
    box-shadow: ${m('--box-shadow-l')};

    > div {
      margin: 0;
    }

    video {
      height: ${imageHeight}px;
      width: 100%;
    }

    ${mobileMediaQuery} {
      video {
        width: ${imgWidthWithIcon}px;
      }
    }
  `;

export const tileWithUserIconCss = css`
  display: flex;
  flex-direction: column;
  border-radius: ${m('--spacing-xxs')};
  background-color: transparent;
  align-items: center;
`;

export const tileUserIconCss = css`
  width: 56px;
  height: 56px;
  margin: 16px auto 0 auto;
`;

export const tileTitleWithUserIconCss = css`
  /* Note: Not using --tile-fg-color here because it has no background. */
  color: ${m('--fg-color')};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  margin-top: 16px;
`;

export const innerTitleWithUserIconCss = css`
  ${p1Css}
  text-align: center;
  padding-left: ${m('--spacing-s')};
  padding-right: ${m('--spacing-s')};
`;

export const tileLabelWithUserIconCss = css`
  ${p2Css}
  text-align: center;
  padding-left: ${m('--spacing-s')};
  padding-right: ${m('--spacing-s')};
`;

export const tilePictureCss = css`
  width: 100%;
  height: 100%;
  object-position: center;
`;

export const coverCss = css`
  object-fit: cover;
`;

export const containCss = css`
  object-fit: contain;
`;

export const tileNoBgImgCss = css`
  background-image: none;
`;

export const pointerCss = css`
  cursor: pointer;
`;
