import { ensureSingleScript } from '@snapchat/mw-common/client';
import { useEffect } from 'react';

import { Config } from '../config';
import { arkosePublicDevKey, arkosePublicProdKey } from '../constants/arkose';
import type { ArkoseMyEnforcement } from '../types/arkose';

export interface CustomArkoseClient {
  run: () => void;
}

interface UseArkoseProps {
  /** The nonce to use for the Arkose script tag if needed for CSP. */
  nonce?: string;
  /**
   * Callback for when the Arkose client is ready to be used. This will only be called for the first
   * instance of arkose being used. This means that if you have two forms on one page both using
   * arkose, only one of the onReadys will fire
   */
  onReady?: () => void;
  /** Callback for when the Arkose get token call has completed. */
  onCompleted?: (token: string) => void;
  /** Callback for when the Arkose get token call has errored. */
  onError?: (error?: string) => void;
  /**
   * Callback for when the Arkose client has been reset, which happens after a successful get token
   * call.
   */
  onReset?: () => void;
  /** A ref to the Arkose client that can be used to call the run method to get the token */
  arkoseClientRef: React.MutableRefObject<CustomArkoseClient | undefined>;
  /** Boolean to disable/enable arkose since not all sites will use it initially */
  enableArkose?: boolean;
  /**
   * The keys allow for separation of Arkose integrations for better tracking and monitoring. A
   * public dev key for Arkose integration
   */
  arkoseDevKey?: string;
  /** A public prod key for Arkose integration */
  arkoseProdKey?: string;
}

let globalMyEnforcement: ArkoseMyEnforcement | undefined;

/**
 * Hook that exposes the global arkose client to the consumer. This is used to get the token from
 * arkose and attach callbacks to ready, completed, error, and reset events.
 *
 * This can also be enabled/disabled via the enableArkose config prop since some sites might not
 * want to use arkose initially.
 */
export const useArkose = ({
  nonce,
  onReady,
  onCompleted,
  onError,
  onReset,
  arkoseClientRef: myEnforcementRef,
  enableArkose,
  arkoseDevKey = arkosePublicDevKey,
  arkoseProdKey = arkosePublicProdKey,
}: UseArkoseProps): void => {
  const publicKey = Config.isDeploymentTypeProd ? arkoseProdKey : arkoseDevKey;

  const scriptId = `arkose-script-${publicKey}`;

  const setMyEnforcementConfig = (myEnforcement: ArkoseMyEnforcement): void => {
    myEnforcement.setConfig({
      onReady: () => {
        onReady?.();
      },
      onCompleted: response => {
        onCompleted?.(response.token);
      },
      onError: response => {
        onError?.(response?.error);
      },
      onReset: () => {
        onReset?.();
      },
    });
  };

  // Helper function to help set the enforcement config before running
  // We have to have a custom run method that sets the config
  // before we get the token because if we have multiple forms
  // on the same page, they will all override each others configs.
  // This way, we just set the config before we request the token so
  // only the correct forms will call onCompleted
  const setMyEnforcementRef = () => {
    if (myEnforcementRef.current) return;

    myEnforcementRef.current = {
      run: () => {
        if (globalMyEnforcement) {
          setMyEnforcementConfig(globalMyEnforcement);
          globalMyEnforcement.run();
        }
      },
    };
  };

  /* Helper function added to window object in case enforcement is triggered via callback from async script loaded */
  const setupEnforcementViaDataCallback = (myEnforcement: ArkoseMyEnforcement): void => {
    globalMyEnforcement = myEnforcement;

    setMyEnforcementRef();

    // set initial config in case there's an onReady callback
    setMyEnforcementConfig(myEnforcement);
  };

  useEffect(
    () => {
      // allow disabling arkose since not all sites will have it initially
      if (!enableArkose) {
        return;
      }

      const attributes: Record<string, string> = {
        type: 'text/javascript',
        'data-callback': 'setupArkoseEnforcement',
        async: 'true',
        defer: 'true',
      };

      if (nonce) {
        attributes.nonce = nonce;
      }

      // if myEnforcement has already been set, just set the ref
      if (globalMyEnforcement) {
        // same reasons as above in setupEnforcement
        setMyEnforcementRef();
      } else {
        // if the global setupEnforcement is not set, set it.
        // the ref will be set when setupEnforcment is called by the arkose script
        // NOTE: need to do this BEFORE calling ensureSingleScript to avoid a race condition.
        if (!window.setupArkoseEnforcement) {
          window.setupArkoseEnforcement = setupEnforcementViaDataCallback;
        }

        ensureSingleScript(
          scriptId,
          `https://snap-api.arkoselabs.com/v2/${publicKey}/api.js`,
          () => {
            // if the global setupEnforcement is not set, set it.
            // the ref will be set when setupEnforcment is called by the arkose script
            if (window.setupArkoseEnforcement) {
              // we handle this case because two forms could fire at the same time,
              // and setupEnforcment might be set at the time for the second form
              setMyEnforcementRef();
            }
          },
          attributes
        );
      }
    },
    // We only want to run once mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
};
