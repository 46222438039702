import { css } from '@emotion/css';

import { mobileMediaQuery, nonMobileMediaQuery } from '../../constants';
import { m } from '../../motif';

const dropdownButtonFontCss = css`
  ${nonMobileMediaQuery} {
    font-size: ${m('--action-desktop-font-size')};
    line-height: ${m('--action-desktop-font-line-height')};
    font-weight: ${m('--action-desktop-font-weight')};
  }

  ${mobileMediaQuery} {
    font-size: ${m('--action-mobile-font-size')};
    line-height: ${m('--action-mobile-font-line-height')};
    font-weight: ${m('--action-mobile-font-weight')};
  }
`;

export const dropdownButtonCss = css`
  /* Reset <button> styles. */
  background: none;

  ${dropdownButtonFontCss}

  background-color: ${m('--dropdown-menu-bg-color')};
  border: ${m('--dropdown-button-border-width')} solid ${m('--dropdown-button-border-color')};
  border-radius: ${m('--dropdown-button-border-radius')};
  box-shadow: ${m('--box-shadow-s')};
  color: ${m('--fg-color')};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  min-width: 160px;
  padding: ${m('--spacing-s')} ${m('--spacing-m')};
  transition: border-color 0.2s linear;

  :hover {
    border-color: ${m('--dropdown-button-hover-border-color')};
  }
`;

export const dropdownButtonIconCss = css`
  margin-left: ${m('--spacing-s')};
  transform: rotate(0deg); /* 'down' */
  transition: transform 250ms;
  fill: ${m('--fg-color')};
  /** Aligning to where it was before. OK to move. */
  position: relative;
  top: 1px;
  left: 2px;
`;

export const dropdownButtonIconUpCss = css`
  transform: rotate(180deg); /* 'up' */
`;
