import { cx } from '@emotion/css';
import type { FC } from 'react';

import { h6Css } from '../../motif';
import { Media } from '../Media';
import {
  subtopicContentWrapperCss,
  subtopicCss,
  subtopicMediaCss,
  subtopicMediaWrapperCss,
} from './styles';
import type { ImageSubtopicProps } from './types';

/**
 * A component for displaying complementary details to support main content using images.
 *
 * This component is designed to be used within the Multi Value Prop Block component that provides
 * structure and layout.
 *
 * @param {string} [title] - The title of the subtopic.
 * @param {ImageSources} [imgSrcs] - An object containing image sources for different screen sizes.
 */

export const ImageSubtopic: FC<ImageSubtopicProps> = props => {
  const { title, imgSrcs } = props;

  return (
    <div className={cx(subtopicCss, subtopicContentWrapperCss)}>
      <div className={subtopicMediaWrapperCss}>
        <Media className={subtopicMediaCss} imgSrcs={imgSrcs} />
      </div>
      {title && <h6 className={h6Css}>{title}</h6>}
    </div>
  );
};
