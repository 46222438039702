import { css } from '@emotion/css';

import { m } from '../../motif/m';

export const primitiveElementClassNames = {
  h1: 'sdsm-h1',
  h2: 'sdsm-h2',
  h3: 'sdsm-h3',
  h4: 'sdsm-h4',
  h5: 'sdsm-h5',
  h6: 'sdsm-h6',
  p: 'sdsm-p',
  ul: 'sdsm-ul',
  ol: 'sdsm-ol',
  li: 'sdsm-li',
};

export const paragraphCss = css`
  margin-bottom: ${m('--spacing-s')};
`;

export const unorderedCss = css`
  padding-left: 20px; /* eyeball guestimate */
  text-align: left;

  *[dir='rtl'] & {
    direction: rtl;
    text-align: right;
    padding-right: 15px;
  }

  *[dir='rtl'] > li & {
    padding-right: 5px;
  }

  & .${primitiveElementClassNames.p} {
    margin-bottom: 0;
  }

  ul& {
    list-style-type: disc;
  }

  ul& > li ul {
    list-style-type: circle;
  }

  ul& > li ul > li ul {
    list-style-type: square;
  }
`;

export const orderedListCss = css`
  padding-left: 20px; /* see above */
  text-align: left;

  & .${primitiveElementClassNames.p} {
    margin-bottom: 0;
  }

  *[dir='rtl'] & {
    direction: rtl;
    text-align: right;
    padding-right: 15px;
  }

  *[dir='rtl'] & li {
    padding-right: 5px;
  }

  ol& {
    list-style-type: decimal;
  }

  ol& > li ol {
    list-style-type: upper-latin;
  }

  ol& > li ol > li ol {
    list-style-type: lower-roman;
  }
`;

export const hrCss = css`
  margin-block: ${m('--spacing-s')};
  border-top: 1px solid ${m('--fg-color')};
`;
