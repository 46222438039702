import { Black, White } from '../../constants';
import type { MotifComponent, MotifComponentProps } from '../../motif';

export const togglePanelWhiteBgVars: MotifComponentProps<MotifComponent.TOGGLE_PANEL> = {
  '--toggle-panel-bg-color': Black.V200,
  '--toggle-panel-color': Black.V200,
};

export const togglePanelYellowBgVars: MotifComponentProps<MotifComponent.TOGGLE_PANEL> = {
  '--toggle-panel-bg-color': White,
  '--toggle-panel-color': Black.V200,
};
