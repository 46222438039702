import { css } from '@emotion/css';

import { mobileMediaQuery, nonMobileMediaQuery } from '../../constants';
import { ZIndex } from '../../constants/zIndex';
import { m } from '../../motif/m';
import { globalHeaderHeight } from '../GlobalHeader';

const linkBeforeCss = `
  content: '';
  position: absolute;
  left: calc(-1 * ${m('--spacing-m')});
  top: 0;
  height: 100%;
  width: ${m('--spacing-xxs')};
  background: ${m('--fg-color')};
  border-radius: 0 ${m('--border-radius-s')} ${m('--border-radius-s')} 0;

  ${mobileMediaQuery} {
    background: ${m('--side-navigation-mobile-fg-active-color')};
  }
`;

/** Value taken from designs. Jira ticket: https://jira.sc-corp.net/browse/WEBP-9654 */
export const desktopSideNavWidthPx = 300;

export const wrapperCss = css`
  background-color: ${m('--side-navigation-desktop-bar-bg-color')};
  z-index: ${ZIndex.SIDENAV};

  ${nonMobileMediaQuery} {
    box-shadow: ${m('--side-navigation-desktop-box-shadow')};
    flex: 0 0 ${desktopSideNavWidthPx}px;

    & > * {
      position: sticky;
      top: ${globalHeaderHeight}px;
    }
  }

  ${mobileMediaQuery} {
    position: sticky;
    top: ${globalHeaderHeight}px;
    width: 100%;
    box-shadow: ${m('--box-shadow-m')};
  }
`;

export const wrapperShadowCss = css`
  ${mobileMediaQuery} {
    box-shadow: ${m('--box-shadow-xs')};
  }
`;

export const containerCss = css`
  font-size: ${m('--action-desktop-font-size')};
  line-height: ${m('--action-desktop-font-line-height')};
  padding-block-start: ${m('--spacing-m')};
  padding-inline: ${m('--spacing-xl')};

  ${mobileMediaQuery} {
    width: auto;
    font-size: ${m('--action-mobile-font-size')};
    line-height: ${m('--action-mobile-font-line-height')};
    background-color: ${m('--side-navigation-mobile-bg-color')};
    padding: ${m('--spacing-s')} ${m('--spacing-xl')};
  }

  & ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  & > ul > li {
    padding-block: ${m('--spacing-m')};
  }

  & > ul > li > ul > li {
    font-size: ${m('--action-desktop-font-size')};
    line-height: ${m('--action-desktop-font-line-height')};
    margin-top: ${m('--spacing-m')};
    margin-inline-start: ${m('--spacing-xl')};
    position: relative;

    ${mobileMediaQuery} {
      font-size: ${m('--action-mobile-font-size')};
      line-height: ${m('--action-mobile-font-line-height')};
    }
  }

  & > ul > li > button {
    padding: 0;
    font-family: 'Graphik', Helvetica, sans-serif;
    display: flex;
    color: ${m('--action-default-color')};
    text-decoration: none;
    font-weight: ${m('--action-desktop-font-weight')};
    background-color: transparent;
    border: 0;
    font-size: ${m('--action-desktop-font-size')};
    line-height: ${m('--action-desktop-font-line-height')};
    text-align: left;
    cursor: pointer;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    min-height: 22px;

    *[dir='rtl'] & {
      text-align: right;
    }

    ${mobileMediaQuery} {
      font-size: ${m('--action-mobile-font-size')};
      line-height: ${m('--action-mobile-font-line-height')};
      justify-content: space-between;
      color: ${m('--side-navigation-mobile-fg-color')};
    }
  }
`;

export const linkCss = css`
  a {
    color: ${m('--action-default-color')};
    display: block;
    text-decoration: none;
    font-size: ${m('--action-desktop-font-size')};
    line-height: 140%;

    @media (hover: hover) {
      &:hover {
        &:before {
          ${linkBeforeCss}
        }
      }
    }

    *[dir='rtl'] & {
      &:before {
        right: calc(-1 * ${m('--spacing-m')});
        border-radius: ${m('--border-radius-s')} 0 0 ${m('--border-radius-s')};
      }
    }

    ${mobileMediaQuery} {
      color: ${m('--side-navigation-mobile-fg-color')};
    }
  }
`;

export const caratCss = css`
  font-size: ${m('--action-desktop-font-size')};

  & svg:first-child {
    width: 22px;
    height: 22px;
    margin: -6px;
  }

  & svg:nth-child(2) {
    display: none;
  }

  & svg:nth-child(3) {
    display: none;
  }

  *[dir='rtl'] & {
    & svg:nth-child(2) {
      display: none;
    }

    & svg:nth-child(3) {
      display: none;
    }
  }
`;

export const activeLinkCss = css`
  position: relative;
  font-weight: ${m('--action-desktop-font-weight')};

  a {
    color: ${m('--fg-color')};

    ${mobileMediaQuery} {
      color: ${m('--side-navigation-mobile-fg-active-color')};
    }
  }

  &:before {
    ${linkBeforeCss}
  }

  *[dir='rtl'] & {
    &:before {
      right: calc(-1 * ${m('--spacing-m')});
      border-radius: ${m('--border-radius-s')} 0 0 ${m('--border-radius-s')};
    }
  }
`;

export const sectionButtonCss = css`
  ${nonMobileMediaQuery} {
    display: none;
  }

  ${mobileMediaQuery} {
    background-color: ${m('--side-navigation-mobile-bar-bg-color')};
    color: ${m('--side-navigation-mobile-bar-fg-color')};
    border: 0;
    padding: 0;
    font: inherit;
    outline: inherit;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-inline: ${m('--spacing-xl')};
    height: 48px;
    margin: auto;

    & > span:first-child {
      font-family: 'Graphik', Helvetica, sans-serif;
      font-size: ${m('--action-mobile-font-size')};
      line-height: ${m('--action-mobile-font-line-height')};
      font-weight: ${m('--action-desktop-font-weight')};
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
`;

export const sideNavIconCss = css`
  fill: ${m('--action-default-color')};
  margin: -4px -12px;

  ${mobileMediaQuery} {
    fill: ${m('--side-navigation-mobile-fg-color')};
  }
`;

export const sideNavMobileIconCss = css`
  width: 22px;
  height: 22px;
  margin: -6px;
  fill: ${m('--side-navigation-mobile-toggler-icon-color')};
`;
