import { cx } from '@emotion/css';
import type { FC, ReactNode } from 'react';

import type { BackgroundColor } from '../../constants';
import { getMotifSchemeForLegacyBackgroundColor, getMotifVariableValue } from '../../motif';
import { MotifComponent, MotifScheme } from '../../motif/motifConstants';
import { useMotifStyles } from '../../motif/motifReactUtils';
import {
  bottomHalfOverlaidCss,
  bottomOverlaidCss,
  breakCss,
  patternCss,
  topHalfOverlaidCss,
  topOverlaidCss,
} from './Break.styled';
import { HeadBreak, HeadFlippedBreak, Skirt2Break, SkirtBreak, StraightBreak } from './templates';
import { BreakOverlayType, Template } from './types';
import { getBreakOverlayType } from './utils';

export interface BreakProps {
  template: Template;
  topColor?: BackgroundColor | MotifScheme;
  bottomColor?: BackgroundColor | MotifScheme;
  isOverlaid?: boolean;
}

/** Spacer between blocks. */
export const Break: FC<BreakProps> = ({ template, topColor, bottomColor, isOverlaid }) => {
  useMotifStyles(MotifComponent.BREAK);

  const isTopTransparent = !topColor;
  const isBottomTransparent = !bottomColor;

  const topScheme = getMotifSchemeForLegacyBackgroundColor(topColor);
  const bottomScheme = getMotifSchemeForLegacyBackgroundColor(bottomColor);
  const topValue = isTopTransparent
    ? 'transparent'
    : getMotifVariableValue(topScheme, '--bg-color') ?? 'transparent';
  const bottomValue = isBottomTransparent
    ? 'transparent'
    : getMotifVariableValue(bottomScheme, '--bg-color') ?? 'transparent';

  let pattern: ReactNode;

  switch (template) {
    case Template.Head:
      pattern = <HeadBreak className={patternCss} topColor={topValue} bottomColor={bottomValue} />;
      break;
    case Template.HeadFlipped:
      pattern = (
        <HeadFlippedBreak className={patternCss} topColor={topValue} bottomColor={bottomValue} />
      );
      break;
    case Template.Skirt:
      pattern = <SkirtBreak className={patternCss} topColor={topValue} bottomColor={bottomValue} />;
      break;
    case Template.Skirt2:
      pattern = (
        <Skirt2Break className={patternCss} topColor={topValue} bottomColor={bottomValue} />
      );
      break;
    case Template.Straight:
      pattern = (
        <StraightBreak className={patternCss} topColor={topValue} bottomColor={bottomValue} />
      );
      break;
    default:
      break;
  }

  if (!pattern) return null;
  if (isTopTransparent && isBottomTransparent) return null;

  // Check to see if this 'Break' will overlay another block/component
  // and, therefore, require a negative margin
  const breakOverlayType = getBreakOverlayType({
    template,
    isTopTransparent,
    isBottomTransparent,
    isOverlaid,
  });

  return (
    <div
      className={cx(
        MotifComponent.BREAK,
        // It doesn't matter which background class name is used here as all set global variables to the same global colours
        // This is just set as a backup in case there is no background class name set higher up in the DOM, in which case
        // the global variables would not be set
        // TODO: remove this when global color vars do not require a background class
        MotifScheme.DEFAULT,
        breakCss,
        {
          [topOverlaidCss]: breakOverlayType === BreakOverlayType.TOP,
          [bottomOverlaidCss]: breakOverlayType === BreakOverlayType.BOTTOM,
          [topHalfOverlaidCss]:
            breakOverlayType === BreakOverlayType.TOP_HALF_AND_BOTTOM_HALF ||
            breakOverlayType === BreakOverlayType.TOP_HALF,
          [bottomHalfOverlaidCss]:
            breakOverlayType === BreakOverlayType.TOP_HALF_AND_BOTTOM_HALF ||
            breakOverlayType === BreakOverlayType.BOTTOM_HALF,
        }
      )}
      data-testid={`break-${template?.toLowerCase()}`}
    >
      {pattern}
    </div>
  );
};

Break.displayName = 'Break';
