import { css } from '@emotion/css';

import { m } from '../../motif';

export const progressBarCss = css`
  &::-moz-progress-bar {
    background: ${m('--progress-bar-progress-color')};
  }
  &::-webkit-progress-bar {
    background: ${m('--progress-bar-bg-color')};
  }
  &::-webkit-progress-value {
    transition: width 0.5s;
    background: ${m('--progress-bar-progress-color')};
  }
  /* -webkit prefix needed by Safari until 2021. */
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-appearance: none;
  appearance: none;
  background-color: ${m('--progress-bar-progress-color')};
  border: none;
  color: ${m('--progress-bar-progress-color')};
  height: 10px;
  margin: 0;
  width: 100%;
  display: block;
`;
