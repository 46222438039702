import { css } from '@emotion/css';
import { BackgroundColor, m } from '@snapchat/snap-design-system-marketing';

import { Config } from '../../config';
import { mediaQuery } from '../../styles/mediaQueries';

export const backgroundColor = Config.theme?.defaultPageBackgroundColor ?? BackgroundColor.White;

export const containerCss = css`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${m('--bg-color')};
  color: ${m('--fg-color')};
  min-height: max(600px, calc(100vh - var(--total-header-height)));
  padding: ${m('--spacing-xxl')};
  text-align: center;
  width: 100%;
`;

export const headingCss = css`
  margin-bottom: 10px;

  ${mediaQuery.over1024_desktop_medium} {
    font-size: 5rem;
    line-height: 5.5rem;
  }
`;

export const minHeightCss = css`
  min-height: calc(100vh - var(--total-header-height));
  min-height: calc(100dvh - var(--total-header-height));
`;
