import { cx } from '@emotion/css';
import type { FC } from 'react';

import { Alignment } from '../../constants';
import { useIsMobile } from '../../hooks';
import { getMotifSchemeForLegacyBackgroundColor, h3Css, MotifComponent, p1Css } from '../../motif';
import { BlockBoundary } from '../BlockBoundary';
import { CarouselCardItem, CarouselV3 } from '../CarouselV3';
import { Media } from '../Media';
import {
  aspectRatio11Css,
  aspectRatio32Css,
  aspectRatio169Css,
  aspectRatio916Css,
  blockContentWrapperCss,
  blockMediaWrapperCss,
  boundaryCss,
  contentWrapperCss,
  ctasContainerCss,
  fullHeightCss,
  headerWrapperCss,
  mainWrapperCss,
  mediaWrapperCss,
  mobileAspectRatio11Css,
  mobileAspectRatio32Css,
  mobileAspectRatio169Css,
  mobileAspectRatio916Css,
  reverseDirectionCss,
  singleColumnCss,
  subtopicsWrapperCss,
} from './styles';
import type { ImageMediaSource, MultiValuePropBlockProps, VideoSource } from './types';

export const MultiValuePropBlock: FC<MultiValuePropBlockProps> = props => {
  const isMobile = useIsMobile();

  const {
    backgroundColor,
    title,
    subtitle,
    body,
    children,
    mediaSources = [],
    desktopMediaAspectRatio = '9:16',
    mobileMediaAspectRatio = '9:16',
    mediaDirection = Alignment.End,
    autoplayCarousel,
    callsToAction,
    fullHeight,
  } = props;

  const mediaSourcesCount = mediaSources?.length || 0;

  const MultiValuePropBlockMedia: FC = () => {
    if (mediaSourcesCount > 0) {
      return (
        <section className={blockMediaWrapperCss}>
          {mediaSourcesCount > 1 ? (
            <CarouselV3 autoPlay={autoplayCarousel} isSingleView={true}>
              {mediaSources.map(
                (item, index) =>
                  item && (
                    <CarouselCardItem
                      key={`${item.type}-${index}`}
                      aspectRatio={isMobile ? mobileMediaAspectRatio : desktopMediaAspectRatio}
                      imgSrcs={(item as ImageMediaSource)?.sources}
                      imgAltText={(item as ImageMediaSource)?.altText}
                      className={cx({
                        [aspectRatio916Css]: !isMobile && desktopMediaAspectRatio === '9:16',
                        [mobileAspectRatio916Css]: isMobile && mobileMediaAspectRatio === '9:16',
                      })}
                      {...(item as VideoSource)}
                    />
                  )
              )}
            </CarouselV3>
          ) : mediaSourcesCount > 0 ? (
            <Media
              className={cx(mediaWrapperCss, {
                [aspectRatio916Css]: desktopMediaAspectRatio === '9:16',
                [mobileAspectRatio916Css]: mobileMediaAspectRatio === '9:16',
                [aspectRatio169Css]: desktopMediaAspectRatio === '16:9',
                [aspectRatio32Css]: desktopMediaAspectRatio === '3:2',
                [aspectRatio11Css]: desktopMediaAspectRatio === '1:1',
                [mobileAspectRatio169Css]: mobileMediaAspectRatio === '16:9',
                [mobileAspectRatio32Css]: mobileMediaAspectRatio === '3:2',
                [mobileAspectRatio11Css]: mobileMediaAspectRatio === '1:1',
              })}
              imgSrcs={(mediaSources[0] as ImageMediaSource)?.sources}
              altText={(mediaSources[0] as ImageMediaSource)?.altText}
              {...(mediaSources[0] as VideoSource)}
            />
          ) : null}
        </section>
      );
    }

    return null;
  };

  return (
    <article
      className={cx(
        MotifComponent.MULTI_VALUE_PROP_BLOCK,
        getMotifSchemeForLegacyBackgroundColor(backgroundColor),
        mainWrapperCss,
        {
          [fullHeightCss]: fullHeight,
        }
      )}
    >
      <BlockBoundary
        className={cx(boundaryCss, {
          [reverseDirectionCss]: !isMobile && mediaDirection === Alignment.Start,
        })}
      >
        <section
          className={cx(blockContentWrapperCss, {
            [singleColumnCss]: mediaSourcesCount === 0,
          })}
        >
          <div className={contentWrapperCss}>
            <div className={headerWrapperCss}>
              <h3 className={h3Css}>{title}</h3>
              {subtitle && <p className={p1Css}>{subtitle}</p>}
            </div>
            {isMobile && <MultiValuePropBlockMedia />}
            {body && body}
          </div>
          {children && <div className={subtopicsWrapperCss}>{children}</div>}
          {callsToAction && <div className={ctasContainerCss}>{callsToAction}</div>}
        </section>
        {!isMobile && <MultiValuePropBlockMedia />}
      </BlockBoundary>
    </article>
  );
};
