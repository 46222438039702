import { css } from '@emotion/css';

import { m } from '../../motif/m';

export const latestPostItemWrapperCss = css`
  width: 100%;
  margin-top: ${m('--spacing-xs')};
  padding: 0 0 ${m('--spacing-m')};
`;

export const latestPostItemTitleCss = css`
  font-size: 2rem;
  font-weight: 600;
  line-height: 2.5rem;
  margin-bottom: 0;

  :hover {
    text-decoration: underline;
  }

  a {
    /* TODO: See if this should be hyperlink color or its own */
    color: ${m('--fg-color')};
    text-decoration: none;
  }
`;
