import { css } from '@emotion/css';

import { m } from '../../motif';

export const radioSelectOptionsWrapperCss = css`
  display: flex;
  flex-direction: column;
  gap: ${m('--spacing-xs')};
`;

export const readonlyCss = css`
  cursor: not-allowed;
  opacity: 0.8;
`;

export const inputRadioWrapperCss = css`
  align-items: center;
  display: flex;
  gap: ${m('--spacing-s')};

  label {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 0;

    a {
      &,
      &:hover,
      &:visited {
        color: ${m('--fg-color')};
      }
    }
  }
`;

export const radioCss = css`
  appearance: none;
  background-color: ${m('--form-input-bg-color')};
  border-radius: 50%;
  border: ${m('--form-input-border-width')} solid ${m('--form-input-border-color')};
  height: 20px;
  margin: 0;
  padding: 0;
  width: 20px;

  &:checked {
    border-color: ${m('--form-input-active-border-color')};
    box-shadow: ${m('--form-input-active-box-shadow')};
  }

  &:checked::before {
    background-color: ${m('--form-input-active-border-color')};
    border-radius: 50%;
    content: '';
    display: block;
    height: 10px;
    margin: 3px;
    width: 10px;
  }

  &:focus-visible {
    outline: ${m('--form-input-border-width')} solid ${m('--form-input-active-border-color')};
    outline-offset: -2px;
  }

  &:not(:checked):hover {
    border-color: ${m('--form-input-hover-border-color')};
    box-shadow: ${m('--form-input-hover-box-shadow')};
  }

  &[required]:not(:checked)::before {
    border-color: ${m('--form-input-error-color')};
  }

  &[readonly] {
    ${readonlyCss}
  }

  &[readonly]:hover {
    border-color: ${m('--form-input-border-color')};
    box-shadow: none;
  }

  &[readonly]:checked {
    border-color: ${m('--form-input-active-border-color')};
    box-shadow: none;
  }
`;

export const radioLabelCss = css`
  margin-top: 0 !important;
`;

export const inputErrorCss = css`
  border: ${m('--form-input-border-width')} solid ${m('--form-input-error-color')};

  &:hover {
    border: ${m('--form-input-border-width')} solid ${m('--form-input-error-color')};
  }
`;
