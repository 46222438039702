import type { FC } from 'react';

import { h6Css } from '../../motif';
import { Icon } from '../Icon';
import { Picture } from '../Picture';
import {
  customIconCss,
  customIconWrapperCss,
  subtopicContentWrapperCss,
  subtopicCss,
  subtopicSdsIconCss,
} from './styles';
import type { TextSubtopicProps } from './types';

/**
 * A component for displaying complementary details to support main content.
 *
 * This component is designed to be used within the Multi Value Prop Block component that provides
 * structure and layout.
 *
 * @param {string} [title] - The title of the subtopic.
 * @param {ReactNode} [body] - The body content of the subtopic
 * @param {string} [icon] - The name of the SDS icon to display. This should correspond to a valid
 *   SDS icon name.
 * @param {ImageSources} [imgSrcs] - An object containing image sources for different screen sizes.
 */
export const TextSubtopic: FC<TextSubtopicProps> = props => {
  const { title, body, icon, imgSrcs } = props;
  return (
    <div className={subtopicCss}>
      {icon ? (
        <Icon name={icon} size={18} className={subtopicSdsIconCss} />
      ) : (
        imgSrcs && (
          <Picture
            className={customIconWrapperCss}
            imgClassName={customIconCss}
            imgSrcs={imgSrcs}
          />
        )
      )}
      <div className={subtopicContentWrapperCss}>
        <h6 className={h6Css}>{title}</h6>
        {body}
      </div>
    </div>
  );
};
