import { Black, Gray, White } from '../../../constants/colors';
import type { MotifComponent, MotifComponentProps } from '../../../motif';
import { m } from '../../../motif/m';

/**
 * Jira ticket: https://jira.sc-corp.net/browse/WEBP-9704 The following value for TableCellMinWidth
 * is dictated by design
 */
const tableCellMinWidth = '156px';

const tableCellDesktopPadding = `${m('--spacing-xl')} ${m('--spacing-m')}`;
const tableCellMobilePadding = `${m('--spacing-xxs')} ${m('--spacing-m')}`;

export const primitiveTableYellowBgVars: MotifComponentProps<MotifComponent.PRIMITIVE_TABLE> = {
  '--table-bg-color': White,
  '--table-border-color': Gray.V250,
  '--table-cell-body-bg-color': White,
  '--table-cell-desktop-padding': tableCellDesktopPadding,
  '--table-cell-fg-color': Black.V200,
  '--table-cell-header-bg-color': Gray.V250,
  '--table-cell-min-width': tableCellMinWidth,
  '--table-cell-mobile-padding': tableCellMobilePadding,
  '--table-desktop-row-gap': '0',
  '--table-first-column-max-width': 'max-content',
  '--table-header-fg-color': Black.V200,
  '--table-mobile-row-gap': m('--spacing-l'),
} as const;

export const primitiveTableLightBgVars: MotifComponentProps<MotifComponent.PRIMITIVE_TABLE> = {
  '--table-bg-color': 'transparent',
  '--table-border-color': Gray.V150,
  '--table-cell-body-bg-color': White,
  '--table-cell-desktop-padding': tableCellDesktopPadding,
  '--table-cell-fg-color': Black.V50,
  '--table-cell-header-bg-color': Gray.V150,
  '--table-cell-min-width': tableCellMinWidth,
  '--table-cell-mobile-padding': tableCellMobilePadding,
  '--table-desktop-row-gap': '0',
  '--table-first-column-max-width': 'max-content',
  '--table-header-fg-color': Black.V200,
  '--table-mobile-row-gap': m('--spacing-l'),
} as const;

export const primitiveTableDarkBgVars: MotifComponentProps<MotifComponent.PRIMITIVE_TABLE> = {
  '--table-bg-color': 'transparent',
  '--table-border-color': Black.V100,
  '--table-cell-body-bg-color': Black.V200,
  '--table-cell-desktop-padding': tableCellDesktopPadding,
  '--table-cell-fg-color': White,
  '--table-cell-header-bg-color': Black.V100,
  '--table-cell-min-width': tableCellMinWidth,
  '--table-cell-mobile-padding': tableCellMobilePadding,
  '--table-desktop-row-gap': '0',
  '--table-first-column-max-width': 'max-content',
  '--table-header-fg-color': White,
  '--table-mobile-row-gap': m('--spacing-l'),
} as const;

export const primitiveTableGrayBgVars: MotifComponentProps<MotifComponent.PRIMITIVE_TABLE> = {
  '--table-bg-color': 'transparent',
  '--table-border-color': Gray.V200,
  '--table-cell-body-bg-color': Gray.V100,
  '--table-cell-desktop-padding': tableCellDesktopPadding,
  '--table-cell-fg-color': Black.V50,
  '--table-cell-header-bg-color': Gray.V200,
  '--table-cell-min-width': tableCellMinWidth,
  '--table-cell-mobile-padding': tableCellMobilePadding,
  '--table-desktop-row-gap': '0',
  '--table-first-column-max-width': 'max-content',
  '--table-header-fg-color': Black.V200,
  '--table-mobile-row-gap': m('--spacing-l'),
} as const;
