import { cx } from '@emotion/css';
import { getDprSrcSetSettingsByHeight, useContentfulImages } from '@snapchat/mw-contentful-client';
import type { Content as ContentType } from '@snapchat/mw-contentful-schema';
import type { Buttons } from '@snapchat/snap-design-system-marketing';
import {
  Content as ContentSDS,
  Media,
  SnapchatEmbed as SnapchatEmbedSDS,
} from '@snapchat/snap-design-system-marketing';
import isNil from 'lodash-es/isNil';
import type { FC, ReactEventHandler, ReactNode } from 'react';
import { useContext, useState } from 'react';

import { AppContext } from '../../AppContext';
import { useParseMediaContainer } from '../../hooks/useParseMediaContainer';
import { UserAction } from '../../types/events';
import { combineImageSources } from '../../utils/combineImageSources';
import { getContentfulInspectorProps } from '../../utils/contentful/getContentfulInspectorProps';
import { getFileInfo } from '../../utils/getFileInfo';
import { parseMedia } from '../../utils/media';
import {
  getRichTextWithEmbeddingsRenderer,
  renderRichTextMultiLineWithMarkings,
} from '../../utils/renderText/renderRichText';
import { CallToAction } from '../CallToAction';
import { ConsumerContext } from '../ConsumerContextProvider';
import type { ImageDataProps } from '../Image/types';
import { LazyImageButton } from '../ImageButton/LazyImageButton';
import type { SnapchatEmbedDataProps } from '../SnapchatEmbed';
import type { VideoDataProps } from '../Video/types';
import { getVideoWatchLogger } from '../Video/utils';
import { mediaOnlyCss, mediaStylesCss } from './Content.styles';
import type { ContentDataProps } from './types';

export const Content: FC<ContentDataProps> = props => {
  const [videoPlayed, setVideoPlayed] = useState(false);
  const { getImageSources } = useContentfulImages();
  const consumerContext = useContext(ConsumerContext);
  const { isRTL } = useContext(AppContext);

  const { logEvent } = consumerContext;

  const {
    sys,
    mediaWrap,
    showVideoControls,
    layout,
    mediaDirection,
    displayOn,
    asset,
    title,
    subtitle,
    titleAlignment,
    titleAlignmentMobile,
    showFullResolutionMedia,
    body,
    bodyAlignment,
    bodyAlignmentMobile,
    callsToActionCollection,
    theme,
    backgroundColor,
    anchorId,
    animationsCollection,
    useHeadingTagsForBody,
    collapseMobileTable,
    listIcon,
  } = props;

  const hasText = title || subtitle || body;
  const isMediaOnly = asset && !hasText;

  const { getBestBgImgSrc } = useContentfulImages();

  const iconUrl = getBestBgImgSrc(listIcon?.media?.url, {
    width: 72,
    height: 72,
    resizeFocus: 'center',
    fit: 'fill',
  });

  const renderBody = getRichTextWithEmbeddingsRenderer(useHeadingTagsForBody, collapseMobileTable);

  const {
    media: { imageSource, imageAltText, imageSize, videoSource },
    mobileMedia: { imageSource: mobileImageSource, videoSource: mobileVideoSource },
  } = useParseMediaContainer(asset as ImageDataProps | VideoDataProps);

  const inspectorDataset = getContentfulInspectorProps<ContentType>({
    entryId: sys.id,
    fieldIds: ['body', 'title', 'subtitle', 'asset'],
  });

  const callsToAction: Buttons = [];

  if (callsToActionCollection?.items) {
    for (const callToAction of callsToActionCollection.items) {
      if (callToAction.__typename === 'ImageButton') {
        callsToAction.push(<LazyImageButton key={callToAction.sys.id} {...callToAction} />);
      } else if (callToAction.__typename === 'CallToAction') {
        callsToAction.push(<CallToAction key={callToAction.sys.id} {...callToAction} />);
      }
    }
  }

  const defaultHeight =
    showFullResolutionMedia && imageSize?.height ? imageSize.height : isMediaOnly ? 600 : 400;
  const imgSrcSetSettings = getDprSrcSetSettingsByHeight(defaultHeight, imageSize?.height ?? 0);

  const imgSrcs = combineImageSources({
    desktop: getImageSources(imageSource, imgSrcSetSettings),
    mobile: getImageSources(mobileImageSource /* TODO(kpan2): Mobile image settings */),
  });

  const mediaHeight = Math.min(imageSize?.height ?? defaultHeight, defaultHeight);

  let prevWatchEventTime = -1;

  const onPlay: ReactEventHandler<HTMLVideoElement> = () => {
    if (logEvent && videoSource && !videoPlayed) {
      if ((asset as VideoDataProps).autoPlay) return; // Skip for auto play videos
      const { fileName } = getFileInfo(videoSource);
      logEvent({ type: UserAction.Play, component: 'Content', label: fileName });
      setVideoPlayed(true);
    }
  };

  let assetChild: ReactNode = undefined;

  if (asset) {
    const typeName = asset.__typename;

    const onTimeUpdate = getVideoWatchLogger({
      autoPlay: (asset as VideoDataProps).autoPlay,
      eventLabel: (asset as VideoDataProps).externalId ?? asset?.sys.id,
      getPreviousWatchTime: () => {
        return prevWatchEventTime;
      },
      setPreviousWatchTime: value => {
        prevWatchEventTime = value;
      },
    });

    if (typeName === 'Video' || typeName === 'Image') {
      const assetMediaWrap = (asset as ImageDataProps | VideoDataProps).wrap;
      const { thumbnailMedia, captions, autoPlay, media } = asset as VideoDataProps;
      const videoAltText = thumbnailMedia?.description ?? media.description;

      const { imageSource: posterSource } = parseMedia(thumbnailMedia);
      const showVideoControlsFinal = showVideoControls ?? (isNil(autoPlay) ? false : !autoPlay);

      // we only want strict hieght for images that are taller than they are wide, as the opposite
      // needs to dynamically set its height based on its dynamic width.
      const isStrictHeight =
        typeName === 'Image' && (imageSize?.height ?? 0) > (imageSize?.width ?? 0);
      const mediaClassName = cx({
        [mediaOnlyCss]: !showFullResolutionMedia && isMediaOnly,
        [mediaStylesCss]: !showFullResolutionMedia && !isMediaOnly,
        'strict-height': !showFullResolutionMedia && isStrictHeight,
      });

      assetChild = (
        <Media
          className={mediaClassName}
          style={{ '--mediaHeight': `${mediaHeight}px` }}
          altText={imageAltText ?? videoAltText}
          imgSrcs={imgSrcs}
          posterSource={posterSource}
          // TODO: Deprecate `mediaWrap` in Content and remove its references.
          wrap={assetMediaWrap ?? mediaWrap}
          dataset={inspectorDataset.assetDataset}
          imageSource={imageSource}
          showVideoControls={showVideoControlsFinal}
          videoSource={videoSource}
          mobileVideoSource={mobileVideoSource}
          onPlay={onPlay}
          onTimeUpdate={onTimeUpdate}
          captionsSource={captions?.url}
        />
      );
    } else if (typeName === 'SnapchatEmbed') {
      assetChild = (
        <SnapchatEmbedSDS
          {...(asset as SnapchatEmbedDataProps)}
          dataset={inspectorDataset.assetDataset}
        />
      );
    }
  }

  const animations = animationsCollection?.items.map(item => ({
    delay: item.keyframes.delay,
    duration: item.keyframes.duration,
    name: item.keyframes.sys.id,
    direction: item.keyframes.direction,
    timingFunction: item.keyframes.timingFunction,
    iterationCount: item.keyframes.iterationCount,
    transformOrigin: item.keyframes.transformOrigin,
    target: item.target,
  }));

  // NOTE: The underlying component can only handle 'undefined' but not 'null', so we coerce the values here.
  return (
    <ContentSDS
      title={renderRichTextMultiLineWithMarkings(title)}
      subtitle={renderRichTextMultiLineWithMarkings(subtitle)}
      titleAlignment={titleAlignment ?? undefined}
      titleAlignmentMobile={titleAlignmentMobile}
      body={renderBody(body)}
      layout={layout}
      mediaDirection={mediaDirection}
      bodyAlignment={bodyAlignment ?? undefined}
      bodyAlignmentMobile={bodyAlignmentMobile}
      callsToAction={callsToAction}
      displayOn={displayOn ?? undefined}
      backgroundColor={backgroundColor ?? theme}
      anchorId={anchorId}
      animations={animations}
      isRTL={isRTL}
      asset={assetChild}
      listIconUrl={iconUrl}
      {...inspectorDataset}
    />
  );
};

Content.displayName = 'Content';
