import { css } from '@emotion/css';

import { Gray, maxWidth, Spacing } from '../../../constants';
import { m } from '../../../motif';
import { mediaQueryForRange } from '../../../utils';

const extraLargeDesktopMediaQuery = mediaQueryForRange({ min: maxWidth });

export const layoutCss = css`
  align-items: center;
  display: flex;
  gap: ${Spacing['3XL']}px;
  margin: 0 auto;
  max-width: ${maxWidth}px;
  padding: 0 ${Spacing['3XL']}px ${Spacing['4XL']}px;
  width: 100%;
`;

export const layoutReverseCss = css`
  flex-direction: row-reverse;
`;

export const accordionContainerCss = css`
  max-width: 50%;
  width: 50%;

  ${extraLargeDesktopMediaQuery} {
    flex: 1;
    max-width: none;
    width: auto;
  }
`;

export const imagesContainerCss = css`
  max-width: 50%;
  width: 50%;

  ${extraLargeDesktopMediaQuery} {
    /* This width value is specified in the designs. The accordion will fill the remaining horizontal space. */
    max-width: 416px;
    min-width: 416px;
    width: 416px;
  }
`;

export const accordionItemCss = css`
  margin-bottom: ${Spacing.XL}px;
  padding-inline-start: ${Spacing.MD}px;
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const accordionItemDetailCss = css`
  scroll-margin: 40px;
  overflow-y: hidden;
  overflow-x: auto;
`;

export const accordionItemDetailContentCss = css`
  font-size: 16px;
  letter-spacing: 0.01em;
  line-height: 26px;

  /* Restrict height of content when not active to prevent initial flash
     of items appearing open without visibility due to functionality of
     the underlying 'DetailSummary' component from SDS-M.
  */
  max-height: 0;
`;

export const accordionItemDetailContentActiveCss = css`
  max-height: none;
  padding-top: ${Spacing.MD}px;
`;

export const accordionItemSummaryCss = css`
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 18px;
  font-weight: 500;
  justify-content: space-between;
  line-height: 28px;
  margin: 0;
  user-select: none;
`;

export const progressIndicatorCss = css`
  background: ${Gray.V300}80; /* 80 = 50% opacity using #RRGGBBAA */
  border-radius: 4px;
  height: 100%;
  inset-block-start: 0;
  inset-inline-start: 0;
  overflow: hidden;
  position: absolute;
  width: 4px;
`;

export const progressIndicatorBarCss = css`
  background: ${m('--fg-color')};
  width: 100%;
  height: 100%;
  transform-origin: center top;
  transform: scaleY(0);
`;

export const imageAspectRatioWrapperCss = css`
  padding-top: 100%;
  position: relative;
  width: 100%;
`;

export const imageCss = css`
  height: 100%;
  left: 50%;
  object-fit: contain;
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 0.2s linear;
  width: 100%;
`;

export const imageActiveCss = css`
  opacity: 1;
`;
