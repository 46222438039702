import { Black, Gray, Plain } from '../../constants';
import type { MotifComponent, MotifComponentProps } from '../../motif';
import { m } from '../../motif/m';

const commonVars = {
  '--hero-subtitle-color': m('--fg-color'),
  // To avoid loss of contrast, the text color should be white when the curtain is active.
  '--hero-text-color-curtain-active': Plain.WHITE,
  '--hero-title-color': m('--fg-color'),
  '--hero-scroll-button-curtain-active-bg-color': Plain.WHITE,
  '--hero-scroll-button-curtain-active-hover-bg-color': Gray.V200,
  '--hero-scroll-button-curtain-active-fg-color': Plain.BLACK,
  '--hero-scroll-button-curtain-active-hover-fg-color': Plain.BLACK,
};

export const heroLightBgVars: MotifComponentProps<MotifComponent.HERO> = {
  ...commonVars,
  '--hero-scroll-button-bg-color': Plain.BLACK,
  '--hero-scroll-button-hover-bg-color': Black.V100,
  '--hero-scroll-button-fg-color': Plain.WHITE,
  '--hero-scroll-button-hover-fg-color': Plain.WHITE,
};

export const heroDarkBgVars: MotifComponentProps<MotifComponent.HERO> = {
  ...commonVars,
  '--hero-scroll-button-bg-color': Plain.WHITE,
  '--hero-scroll-button-hover-bg-color': Gray.V200,
  '--hero-scroll-button-fg-color': Plain.BLACK,
  '--hero-scroll-button-hover-fg-color': Plain.BLACK,
};
