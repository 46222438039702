import { css } from '@emotion/css';

import { nonMobileMediaQuery, nonTwoColumnCollapseMediaQuery } from '../../../constants';
import { m } from '../../../motif/m';

export const footerHeaderCss = css`
  font-size: 14px;
  color: ${m('--fg-color')};
  font-weight: 700;
  padding-inline-end: ${m('--spacing-xl')};
  word-break: break-word;

  ${nonMobileMediaQuery} {
    padding-inline-end: 0;
  }
`;

export const footerHeaderSpacingCss = css`
  margin-block-end: ${m('--spacing-xs')};
`;

export const footerGroupCss = css`
  border-bottom: 1px solid ${m('--footer-border-color')};
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  padding-block: ${m('--spacing-m')};
  padding-inline: 0 ${m('--spacing-m')};

  &:first-child {
    padding-block-start: ${m('--spacing-s')};
  }

  ${nonMobileMediaQuery} {
    border-bottom: 0;
    padding-bottom: ${m('--spacing-xxs')};
    padding-inline: 0;
    padding-top: 0;

    &:first-child {
      padding-block-start: 0;
    }
  }
`;

export const footerColumnListCss = css`
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin: 0;
  padding-block: ${m('--spacing-s')} 0;
  padding-inline: 0;
  width: 100%;

  ${nonMobileMediaQuery} {
    padding: 0;
  }
`;

export const footerHorizontalLayoutCss = css`
  a {
    margin-inline: 0;
    white-space: unset;
  }

  li {
    margin: 0;
  }

  align-items: flex-start;
  border: 0;
  display: flex;
  flex-direction: column;
  gap: ${m('--spacing-m')};
  padding-block: ${m('--spacing-s')};

  ${nonMobileMediaQuery} {
    align-items: center;
    flex-direction: row;
    gap: ${m('--spacing-l')};
    padding-block: 0;

    &:first-child {
      padding-block-start: 0;
    }
  }

  ${nonTwoColumnCollapseMediaQuery} {
    &:first-child {
      margin-inline-start: 0;
    }

    &:last-child {
      margin-inline-end: 0;
    }
  }
`;
