import type { ImageSubtopic as ImageSubtopicType } from '@snapchat/mw-contentful-schema';
import { ImageSubtopic as ImageSubtopicSDS } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';

import {
  getDprSrcSetSettingsByHeight,
  useContentfulImages,
} from '../../../../contentful-client/src';
import { combineImageSources } from '../../utils/combineImageSources';

export const ImageSubtopic: FC<ImageSubtopicType> = props => {
  const { getImageSources } = useContentfulImages();

  const { title, image } = props;

  const imgSrcs = combineImageSources({
    desktop: getImageSources(
      image?.media?.url,
      getDprSrcSetSettingsByHeight(120, image?.media?.height ?? 0)
    ),
    mobile: getImageSources(
      image?.mobileMedia?.url,
      getDprSrcSetSettingsByHeight(120, image?.mobileMedia?.height ?? 0)
    ),
  });

  return <ImageSubtopicSDS title={title} imgSrcs={imgSrcs} />;
};
