// TODO: Move this into common.
import { Site } from '@snapchat/mw-common';
import type { FC } from 'react';
import { useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

import { Feature, useFeatureFlags } from './components/FeatureFlags';
import { Slug } from './components/Slug';
import { Config } from './config';
import { cookieSettingsPath } from './helpers/trackingCookies';
import { CookieSettings } from './pages/CookieSettings';
import { NoMatch } from './pages/NoMatch';
import { Search } from './pages/Search';
import { PoliticalAdAsset } from './sites/snap/components/PoliticalAdAsset/PoliticalAdAsset';

export const Routes: FC = () => {
  const location = useLocation();
  const featureFlags = useFeatureFlags();

  const enableSearchFeature = featureFlags[Feature.ENABLE_SITE_SEARCH] === 'true';

  useEffect(() => {
    if (location.hash) {
      let element = document.getElementById(location.hash.slice(1));

      if (element) {
        // if element exists, immediately scroll to element
        element?.scrollIntoView({ behavior: 'smooth' });
        return;
      }

      // if element doesn't exist (SSR) set element and scroll after timeout
      setTimeout(() => {
        element = document.getElementById(location.hash.slice(1));
        element?.scrollIntoView({ behavior: 'smooth' });
      }, 1000);
    }
  }, [location.hash]);

  const renderedRoute = (
    <>
      <Switch>
        <Route
          exact
          path={cookieSettingsPath}
          render={() => (
            <CookieSettings
              backgroundColor={Config.theme?.defaultPageBackgroundColor}
              cookieDomain={Config.trackingSettings.cookieDomain}
              deploymentType={Config.deploymentType}
              domainName={Config.domainName}
              isClient={Config.isClient}
            />
          )}
        />
        {enableSearchFeature && <Route exact path="/search" component={Search} />}
        {Config.site === Site.SNAP && (
          <Route path="/political-ads/asset/:assetId" component={PoliticalAdAsset} />
        )}
        <Route path="/404" component={NoMatch} />
        {/* Needs to match all paths, including '' (default path after prefix, i.e. /fr-FR) */}
        <Route path="*" component={Slug} />
      </Switch>
    </>
  );

  return renderedRoute;
};
