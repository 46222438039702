import { m } from '../../motif/m';
import type { MotifComponent } from '../../motif/motifConstants';
import type { MotifComponentProps } from '../../motif/motifTypes';

// NOTE: For the size, titles are just H2s.
// They are specified here so we can override them in Arcadia.
export const defaultBlockVars: MotifComponentProps<MotifComponent.BLOCK> = {
  '--block-title-color': m('--fg-color'),
  '--block-subtitle-color': m('--fg-color'),
  '--block-eyebrow-color': m('--fg-color'),
  '--block-title-desktop-font-size': m('--h2-desktop-font-size'),
  '--block-title-desktop-font-stretch': m('--h2-desktop-font-stretch'),
  '--block-title-desktop-font-line-height': m('--h2-desktop-font-line-height'),
  '--block-title-mobile-font-size': m('--h2-mobile-font-size'),
  '--block-title-mobile-font-line-height': m('--h2-mobile-font-line-height'),
  '--block-header-desktop-padding': `${m('--spacing-m')} ${m('--spacing-xl')}`,
  '--block-header-mobile-padding': m('--spacing-m'),
  '--block-boundary-desktop-padding': m('--spacing-xl'),
  '--block-boundary-mobile-padding': m('--spacing-m'),
};
