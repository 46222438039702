import { supportsAvif } from '@snapchat/client-hints';
import type { GetImageSources, SrcSetSizes } from '@snapchat/mw-contentful-client';
import {
  getDprDataByHeight,
  getDprSrcSetSettingsByHeight,
  getSrcSetUrl,
  useContentfulImages,
} from '@snapchat/mw-contentful-client';
import type { BlockHero as BlockHeroType } from '@snapchat/mw-contentful-schema';
import { globalNavHighlightSizes } from '@snapchat/mw-global-components';
import {
  BackgroundMediaLayout,
  BrowserFeaturesContext,
  Hero as HeroSDS,
  HeroSize,
  Media as MediaSDS,
  SnapchatEmbed as SnapchatEmbedSDS,
} from '@snapchat/snap-design-system-marketing';
import isNull from 'lodash/isNull.js';
import omit from 'lodash/omit.js';
import type { FC } from 'react';
import { useContext, useRef } from 'react';
import { Helmet } from 'react-helmet-async';

import { Config } from '../../config';
import { useParseMediaContainer } from '../../hooks/useParseMediaContainer';
import type { ContentfulSysProps } from '../../types/contentful';
import { combineImageSources } from '../../utils/combineImageSources';
import { getContentfulInspectorProps } from '../../utils/contentful/getContentfulInspectorProps';
import { parseMedia } from '../../utils/media';
import {
  renderRichTextMultiLineWithMarkings,
  renderRichTextWithElementsNoHeadings,
} from '../../utils/renderText/renderRichText';
import { CallToAction } from '../CallToAction/CallToAction';
import { CarouselV3 } from '../CarouselV3';
import { getHeaderHeightFromCssVar } from '../Header/headerSizeUtils';
import type { ImageDataProps } from '../Image';
import { isImageDataProps } from '../Image';
import type { SnapchatEmbedDataProps } from '../SnapchatEmbed';
import { isVideoDataProps } from '../Video';
import { getHeroHeaderComponent } from './getHeroHeaderComponent';
import type { Foreground, HeroRenderProps } from './types';
import { isCarouselV3 } from './utils';

// Header image is always 84px so we just handle the different DPRs.
const headerImgSrcSetSizes: SrcSetSizes = {
  sizeToUrl: [84, 168, 252].map((width, i) => ({
    size: `${i + 1}x`,
    settings: { width },
  })),
  sizes: globalNavHighlightSizes,
};

const getForegroundMedia = (
  foreground: Foreground,
  getImageSources: GetImageSources,
  dataset?: DOMStringMap,
  videoSource?: string
) => {
  if (isVideoDataProps(foreground)) {
    const mobileVideoSource = parseMedia(foreground.mobileMedia).videoSource;
    return (
      <MediaSDS mobileVideoSource={mobileVideoSource} videoSource={videoSource} dataset={dataset} />
    );
  }

  if (isImageDataProps(foreground)) {
    const { imageSource, imageAltText, imageSize } = parseMedia(foreground.media);
    const { imageSource: mobileImageSource, imageSize: mobileIMageSize } = parseMedia(
      foreground.mobileMedia
    );

    const imgSrcs = combineImageSources({
      desktop: getImageSources(
        imageSource,
        getDprSrcSetSettingsByHeight(650, imageSize?.height ?? 0)
      ),
      mobile: getImageSources(
        mobileImageSource ?? imageSource,
        getDprSrcSetSettingsByHeight(650, mobileIMageSize?.height ?? 0)
      ),
    });

    return <MediaSDS imgSrcs={imgSrcs} altText={imageAltText} dataset={dataset} />;
  }

  // TODO: Update CarouselV3 to handle datasets
  if (isCarouselV3(foreground)) {
    return <CarouselV3 {...foreground} />;
  }

  if ((foreground as ContentfulSysProps).__typename === 'SnapchatEmbed') {
    return <SnapchatEmbedSDS {...(foreground as SnapchatEmbedDataProps)} dataset={dataset} />;
  }

  return undefined;
};

export const Hero: FC<HeroRenderProps> = props => {
  const {
    eyebrow,
    title,
    subTitle,
    body,
    backgroundMediaLayout = BackgroundMediaLayout.FullScreen,
    backgroundMediaV2,
    callsToActionCollection,
    isHeaderDate = false,
    header,
    headerMediaV2,
    backgroundColor,
    theme,
    textAlign,
    textAlignMobile,
    verticalTextAlign,
    fitWindow = false,
    curtainOpacityPercentage,
    foreground,
    mediaWrap,
    className,
    footer,
    showMediaMobile,
    anchorId,
    sys,
    postChildren,
    size,
    showScrollButton,
  } = props;

  const browserFeatures = useContext(BrowserFeaturesContext);

  const containerRef = useRef<HTMLElement>(null);

  const { fallbackDate, shareable = false } = props;

  // New background media
  const {
    media: {
      imageSource: backgroundImageSource,
      videoSource: backgroundVideoSource,
      imageSize: backgroundImageSize,
    },
    mobileMedia: {
      imageSource: mobileBackgroundImageSource,
      videoSource: mobileBackgroundVideoSource,
      imageSize: mobileBackgroundImageSize,
    },
  } = useParseMediaContainer(backgroundMediaV2);

  // New header media
  const headerMediaSource = (headerMediaV2 as ImageDataProps)?.media;
  const { imageSource: headerImageSource, imageAltText: headerImageAltText } =
    parseMedia(headerMediaSource);

  // New header mobile media
  const mobileHeaderMediaSource = (headerMediaV2 as ImageDataProps)?.mobileMedia;
  const { imageSource: mobileHeaderImageSource } = parseMedia(mobileHeaderMediaSource);

  const callsToAction = callsToActionCollection?.items?.map(item => (
    <CallToAction key={item.sys.id} {...item} />
  ));
  const inspectorDataset = getContentfulInspectorProps<BlockHeroType>({
    entryId: sys.id,
    fieldIds: ['eyebrow', 'title', 'subTitle', 'body', 'header', 'foreground'],
  });

  const { getImageSources } = useContentfulImages();

  // If foreground media is a video, we have to get the videoSource and pass it to `getForegroundMedia` because the videoSource
  // has to come from the useParseMediaContainer hook (to support hls streaming) and hooks can only
  // be called at the top level of a component (not in a helper function).
  //
  // TODO: Explore ways to refactor this so we can either get rid of the getForegroundMedia helper function or widen the
  // scope of the useParseMediaContainer hook to handle all possible forms of foreground media.
  const {
    media: { videoSource: foregroundMediaVideoSource },
  } = useParseMediaContainer(foreground && isVideoDataProps(foreground) ? foreground : undefined);

  const foregroundMedia = foreground
    ? getForegroundMedia(
        foreground,
        getImageSources,
        inspectorDataset.foregroundDataset,
        foregroundMediaVideoSource
      )
    : undefined;
  const bg = isNull(backgroundColor) ? undefined : backgroundColor;
  const alternativeBackground = isNull(theme) ? undefined : theme;

  const HeroHeader = getHeroHeaderComponent({
    header,
    isExpectingDate: isHeaderDate,
    fallbackDate,
  });

  const headerSettings = { size: headerImgSrcSetSizes };
  const headerImgSrcs = combineImageSources({
    desktop: getImageSources(headerImageSource, headerSettings),
    mobile: getImageSources(mobileHeaderImageSource, headerSettings),
  });
  const bgImgDprSettings = getDprSrcSetSettingsByHeight(800, backgroundImageSize?.height ?? 0);
  const mobileBgImgDprSettings = getDprSrcSetSettingsByHeight(
    800,
    mobileBackgroundImageSize?.height ?? 0
  );

  const bgImgDprData = getDprDataByHeight(800, backgroundImageSize?.height ?? 0);

  const bgImgSrcs = combineImageSources({
    mobile: getImageSources(mobileBackgroundImageSource, mobileBgImgDprSettings),
    desktop: getImageSources(backgroundImageSource, bgImgDprSettings),
  });
  // Placeholder for 1x dpr avif src if exists
  let baseAvifSrc:
    | {
        url: string;
        // not defined when not using dpr src set
        dpr?: number;
      }
    | undefined;

  // Placeholder for high dpr avif src if exists
  let additionalAvifSrcWithDpr:
    | {
        url: string;
        dpr: number;
      }
    | undefined;

  if (supportsAvif(browserFeatures.getLowEntropyHints()) && backgroundImageSource && bgImgSrcs) {
    const avifSrc = bgImgSrcs.sources.find(src => src.type === 'image/avif')?.url;

    // if we have multiple DPRs being handled, generate the urls individually
    if (bgImgDprData.additionalDpr) {
      baseAvifSrc = {
        url: getSrcSetUrl(backgroundImageSource, {
          image: { format: 'avif', ...bgImgDprData.baseDprSettings },
        }),
        dpr: bgImgDprData.additionalDpr.dpr - 0.0001,
      };

      additionalAvifSrcWithDpr = {
        url: getSrcSetUrl(backgroundImageSource, {
          image: { ...bgImgDprData.additionalDpr.settings, format: 'avif' },
        }),
        dpr: bgImgDprData.additionalDpr.dpr,
      };
    } else if (avifSrc) {
      baseAvifSrc = { url: avifSrc };
    }
  }

  let heroSize: HeroSize = size ?? HeroSize.Regular;

  if (foreground && isCarouselV3(foreground)) {
    heroSize = HeroSize.Regular;
  }

  const handleScrollDownButtonClick = () => {
    if (!containerRef.current) return;

    const headerHeight = getHeaderHeightFromCssVar() ?? 0;

    // Scroll to next block (end of this block)
    const rect = containerRef.current.getBoundingClientRect();
    const scrollPos = rect.bottom + window.scrollY - headerHeight;
    window.scrollTo({ top: scrollPos, left: 0, behavior: 'smooth' });
  };

  return (
    <>
      {/*
        if SSR and we know for sure the browser supports avif, we will preload the hero bg img
        unfortunately for macs, we can't know for sure if it supports avif.

        We use the webkit prefixed media query because resolution is not as well supported
        https://developer.mozilla.org/en-US/docs/Web/CSS/@media/-webkit-device-pixel-ratio
      */}
      {!Config.isClient && baseAvifSrc && (
        <Helmet>
          <link
            rel="preload"
            href={baseAvifSrc.url}
            as="image"
            // @ts-ignore this attribute is chrome only and doesn't exist on typings yet because its so new
            // eslint-disable-next-line react/no-unknown-property
            fetchpriority="high"
            // only add media if multiple dprs handled
            media={
              additionalAvifSrcWithDpr ? `(-webkit-max-device-pixel-ratio: ${baseAvifSrc.dpr})` : ''
            }
          />
          {additionalAvifSrcWithDpr && (
            <link
              rel="preload"
              href={additionalAvifSrcWithDpr.url}
              as="image"
              // @ts-ignore this attribute is chrome only and doesn't exist on typings yet because its so new
              // eslint-disable-next-line react/no-unknown-property
              fetchpriority="high"
              media={`(-webkit-min-device-pixel-ratio: ${additionalAvifSrcWithDpr.dpr})`}
            />
          )}
        </Helmet>
      )}
      <HeroSDS
        ref={containerRef}
        eyebrow={eyebrow}
        title={renderRichTextMultiLineWithMarkings(title)}
        subTitle={renderRichTextWithElementsNoHeadings(subTitle)}
        body={renderRichTextWithElementsNoHeadings(body)}
        backgroundMediaLayout={backgroundMediaLayout}
        backgroundVideoSource={backgroundVideoSource}
        mobileBackgroundVideoSource={mobileBackgroundVideoSource}
        callsToAction={callsToAction?.length ? callsToAction : undefined}
        header={HeroHeader ? <HeroHeader /> : undefined}
        foregroundMedia={foregroundMedia}
        backgroundColor={bg || alternativeBackground}
        bgImgSrcs={bgImgSrcs}
        headerImgSrcs={headerImgSrcs}
        headerImgAltText={headerImageAltText}
        textAlign={textAlign}
        textAlignMobile={textAlignMobile}
        verticalTextAlign={verticalTextAlign}
        fitWindow={isNull(fitWindow) ? false : fitWindow}
        curtainOpacityPercentage={curtainOpacityPercentage ?? undefined}
        wrapMedia={mediaWrap}
        shareable={shareable}
        className={className}
        footer={footer}
        showMediaMobile={showMediaMobile}
        anchorId={anchorId}
        {...omit(inspectorDataset, 'foregroundDataset')}
        postChildren={postChildren}
        size={heroSize}
        showScrollButton={showScrollButton}
        onScrollDownButtonClick={handleScrollDownButtonClick}
      />
    </>
  );
};

Hero.displayName = 'Hero';
