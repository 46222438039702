import { cx } from '@emotion/css';
import type { ReactEventHandler } from 'react';
import { forwardRef, useContext, useEffect, useRef } from 'react';

import { BrowserFeaturesContext } from '../../BrowserFeatures';
import type { BaseComponentProps } from '../../types';
import { dataSetToAttributes } from '../../utils';
import { combineRefs } from '../../utils/useCombinesRefs';
import { TabsContext } from '../Tabs/TabsContext';
import { getObjectFit, videoCss } from './styles';
import { isVideoPlaying } from './utils';

export interface VideoProps extends BaseComponentProps {
  source: string;
  mobileSource?: string;
  sourceType?: string;
  mobileSourceType?: string;
  posterSource?: string;
  altText?: string;
  muted?: boolean;
  autoPlay?: boolean;
  loop?: boolean;
  controls?: boolean;
  playsInline?: boolean;
  onPlay?: ReactEventHandler<HTMLVideoElement>;
  captionsSource?: string;
  isBackgroundVideo?: boolean;
  onTimeUpdate?: ReactEventHandler<HTMLVideoElement>;
}

export const Video = forwardRef<HTMLElement, VideoProps>((props, ref) => {
  const {
    source,
    mobileSource,
    sourceType = 'video/mp4',
    mobileSourceType = 'video/mp4',
    posterSource,
    altText,
    className,
    style,
    muted = true,
    autoPlay = true,
    loop = true,
    controls = false,
    playsInline = true,
    onPlay,
    captionsSource,
    isBackgroundVideo,
    onTimeUpdate,
    dataset,
  } = props;

  const videoRef = useRef<HTMLVideoElement>(null);
  const { getLowEntropyHints } = useContext(BrowserFeaturesContext);
  const clientHints = getLowEntropyHints();

  useEffect(() => {
    const currentTrack = videoRef.current?.querySelector('track');

    if (videoRef.current && captionsSource && !currentTrack) {
      const track = document.createElement('track');
      track.setAttribute('src', captionsSource);
      track.setAttribute('kind', 'captions');
      track.setAttribute('srclang', 'en');
      track.setAttribute('label', 'English');
      videoRef.current.appendChild(track);
    }
  }, [captionsSource]);

  // Workaround for when videos don't autoplay even though they should. Mobile Chrome has issues with
  // loading more than ~4 autoplaying videos on the same page. This shouldn't happen, so we should
  // still address it. Tech debt ticket: https://jira.sc-corp.net/browse/ENTWEB-9038
  //
  // As of 02/12/2024 this issue seems to only effect below the fold videos. Once the video comes into
  // view, the video immediately plays. This means there is no perceptible difference to the end user
  // (in fact, there may be performance benefits from delaying the playback).
  //
  // TODO: We should probably do away with this workaround altogether. If there is no end user benefit
  // and customers do not care about videos autoplaying below the fold, then we should just let chrome
  // do its thing. The action item is to check with customers to see if they have opinions about this
  // behavior (https://jira.sc-corp.net/browse/WEBP-11526).
  useEffect(() => {
    function forceAutoplay(videoElement: HTMLVideoElement) {
      videoElement
        .play()
        .then(() => {}) // For some reason `await` doesn't work
        .catch(console.debug); // It's probably because the browser is objecting to play() without user interaction - but it still works
    }

    if (!videoRef.current) return;

    // We only want to force autoplay the video if it is in a playable state, otherwise we have to
    // wait for it to be playable which can be done by listening for the `canplay` event. This is
    // useful for videos that are not ready to be played by the time this effect runs (such as m3u8
    // streams which may require external libraries to initialize before the video can play).

    const video = videoRef.current;
    const isPlayable = video?.readyState > video.HAVE_CURRENT_DATA;

    // If browser has already started playing the video, no need to force it
    const isPlaying = video.currentTime > 0 && !video.paused && !video.ended && isPlayable;
    if (isPlaying) return;

    // If video attributes dictated that it should not autoplay, no need to force it
    const shouldAutoPlay = autoPlay && muted && !controls;
    if (!shouldAutoPlay) return;

    if (isPlayable) {
      forceAutoplay(video);
    } else {
      videoRef.current?.addEventListener('canplay', () => forceAutoplay(video), {
        once: true,
      });
    }
  }, [autoPlay, controls, muted]);

  const { addOnTabChangeListener, removeOnTabChangeListener } = useContext(TabsContext);

  // Handle when tabs have changed to pause videos
  useEffect(() => {
    // only pause videos that have sound / autoplay
    if (!videoRef.current || muted || autoPlay) {
      return;
    }

    const onTabChange = () => {
      if (isVideoPlaying(videoRef.current || undefined)) {
        videoRef.current?.pause();
      }
    };
    addOnTabChangeListener?.(onTabChange);
    return () => removeOnTabChangeListener?.(onTabChange);
  }, [addOnTabChangeListener, removeOnTabChangeListener, videoRef, muted, autoPlay]);

  const objectFitStyle = getObjectFit(isBackgroundVideo);

  // Preload determines how much of the video to load.
  // 'none' - don't load anything
  // 'auto' - load the whole video
  // 'metadata' - load only the necessary metadata to display the video (thumbnail, video duration)
  const preload = posterSource ? 'none' : autoPlay ? 'auto' : 'metadata';
  const isSafari = clientHints.browsers.find(browser => browser.brand === 'Safari');

  // This is a workaround for Safari not loading the first frame to use as the thumbnail image.
  // This sets the video starting position to 0.01 seconds into the video, which forces a frame to load
  // See: https://stackoverflow.com/questions/56428378/no-way-to-preload-first-video-frame-on-ios-safari
  const videoSource = isSafari && preload === 'metadata' ? `${source}#t=0.01` : source;
  const mobileVideoSource =
    mobileSource && isSafari && preload === 'metadata' ? `${mobileSource}#t=0.01` : mobileSource;

  return (
    <video
      className={cx('video', videoCss, objectFitStyle, className)}
      style={style}
      poster={posterSource}
      autoPlay={autoPlay}
      loop={loop}
      muted={muted}
      controls={controls}
      playsInline={playsInline}
      onPlay={onPlay}
      preload={preload}
      // See: https://www.w3.org/WAI/PF/HTML/wiki/Media_Alt_Technologies
      aria-describedby={altText}
      ref={combineRefs(videoRef, ref)}
      // See: https://stackoverflow.com/questions/55645393/issue-with-loading-vtt-from-cross-domain
      crossOrigin="anonymous"
      key={source}
      onTimeUpdate={onTimeUpdate}
      {...dataSetToAttributes(dataset)}
    >
      {mobileVideoSource && (
        <source src={mobileSource} type={mobileSourceType} media="(max-width: 768px)" />
      )}
      <source src={videoSource} type={sourceType} />
    </video>
  );
});

Video.displayName = 'Video';
