import { gray, red, redBright } from 'ansis';
import camelCase from 'lodash-es/camelCase';
import cloneDeep from 'lodash-es/cloneDeep';

import { FontFamily } from '../../components';
import { BackgroundColor } from '../../constants';
import type { Motif, MotifStyles, MotifVar } from '../../motif';
import { MotifComponent, MotifScheme, motifVariables } from '../../motif';
import type { FigmaToken, FigmaTokenSingleFileExport } from './figmaTokenTypes';

const schemes: Record<string, MotifScheme> = {
  'Snap Motif/Global': MotifScheme.DEFAULT,
  'Snap Motif/Primary': MotifScheme.DEFAULT,
  'Snap Motif/Secondary': MotifScheme.SECONDARY,
  'Snap Motif/Tertiary': MotifScheme.TERTIARY,
  'Snap Motif/Quaternary': MotifScheme.QUATERNARY,
  'Snap Motif/Quinary': MotifScheme.QUINARY,
};

const legacyNames: Record<MotifScheme, BackgroundColor> = {
  [MotifScheme.DEFAULT]: BackgroundColor.Yellow,
  [MotifScheme.SECONDARY]: BackgroundColor.Black,
  [MotifScheme.TERTIARY]: BackgroundColor.White,
  [MotifScheme.QUATERNARY]: BackgroundColor.Gray,
  [MotifScheme.QUINARY]: BackgroundColor.Black,
};

/** How many times to try to parse a variable before quitting. */
const nodeAttempts = 3;

const componentByVariable = Object.entries(motifVariables)
  .flatMap(([component, componentVars]) => componentVars.map(varName => [varName, component]))
  .reduce((record, [varName, component]) => {
    record[varName as MotifVar] = component as MotifComponent;
    return record;
  }, {} as Record<MotifVar, MotifComponent>);

const allMotifVars = new Set<MotifVar>(Object.keys(componentByVariable) as MotifVar[]);

/**
 * Parses object values from figma tokens.
 *
 * Note that if this is a reference to another variable, that does not get expanded here.
 */
function parseValue(token: FigmaToken, currentPath: string): string {
  if (typeof token.value === 'string') {
    return token.value;
  }

  if (token.type === 'boxShadow') {
    const dropShadowValue = [
      token.value.x,
      token.value.y,
      token.value.blur,
      token.value.spread,
      token.value.color,
    ].join(' ');

    return token.value.type === 'innerShadow' ? `inset ${dropShadowValue}` : dropShadowValue;
  }

  throw new Error(
    `Error: Parsing of values with type "${
      token.type
    }" with no string values is not supported.\n${gray(
      `Path: ${currentPath}`
    )} | Token Value: ${JSON.stringify(token)}`
  );
}

/** Converts a dump from figma tokens into a motif file. */
export function figmaTokensToMotif(
  name: string,
  figmaTokensFile: FigmaTokenSingleFileExport,
  onError?: (error: Error) => void
): Motif {
  const motif: Motif = {
    name,
    fontFamily: FontFamily.GRAPHIK, // Will be overwritten later.
    'sdsm-default': {
      name: 'Imported Deafult',
      legacyName: legacyNames[MotifScheme.DEFAULT],
    } as MotifStyles,
  };

  /**
   * Lookup map i.e. "Primary.V150" => "{Palette.Yellow.V150}" and "Palette.Yellow.V150" =>
   * "#FCF000"
   */
  const figmaTokenReferences = new Map<string, string>();
  /** Lookup map i.e. "Root.action-default-color" => "--action-default-color" */
  const motifVariableReferences = new Map<string, string>();

  for (const [schemeName, componentProps] of Object.entries(figmaTokensFile)) {
    const scheme = schemes[schemeName];

    // Skipping $metadata and $theme.
    if (schemeName.startsWith('$')) {
      continue;
    }

    // TODO: This currently fails when we export a theme with a name like Avalon/Primary.
    // Fix this to handle different scheme names OR always export as Snap Motif... TBD
    // https://jira.sc-corp.net/browse/WEBP-10014
    if (!scheme) {
      onError?.(new Error(`Unrecognized scheme name "${schemeName}"`));
      continue;
    }

    if (!componentProps) continue;

    if (!motif[scheme])
      motif[scheme] = {
        // Copy over the primary vars. This is important to enuse that
        // vars referencing other vars in the Primary scheme get used in others.
        // I.e. (In default) --button-border-radius: var(--border-radius-m)
        // and we expect the same definition to propagate to others even though
        // they don't have neither of the definitions set.
        ...(scheme === MotifScheme.DEFAULT ? undefined : cloneDeep(motif[MotifScheme.DEFAULT])),
        name: `Imported ${camelCase(scheme.substring(5))}`,
        legacyName: legacyNames[scheme],
      } as MotifStyles;

    type QueueItem = {
      currentPath: string;
      object: object;
      attemptsLeft: number;
    };

    const objects: QueueItem[] = [
      { object: componentProps, currentPath: '', attemptsLeft: nodeAttempts },
    ];

    while (objects.length > 0) {
      // Iterate as BFS (DFS does not work).
      const { currentPath, object: current, attemptsLeft } = objects.shift()!;

      /** Either returns immediately and logs the error or puts the item back on the queue. */
      // eslint-disable-next-line no-inner-declarations
      function maybeRetry(errorMessage: string) {
        if (!attemptsLeft) {
          onError?.(new Error(errorMessage));
          return;
        }

        objects.push({
          currentPath,
          object: current,
          attemptsLeft: attemptsLeft - 1,
        });
      }

      if (!current) {
        onError?.(new Error(`Found an empty value at path ${current}`));
        continue;
      }

      if (typeof current !== 'object') {
        onError?.(new Error(`Found value "${current}" that is't an object. Bad data.`));
        continue;
      }

      if (!('type' in current)) {
        for (const [key, value] of Object.entries(current)) {
          objects.push({
            currentPath: currentPath === '' ? key : `${currentPath}.${key}`,
            object: value,
            attemptsLeft: nodeAttempts,
          });
        }
        continue;
      }

      const token = current as FigmaToken;

      if (token.type === 'typography') {
        // Attempt to capture the font family from any typography.
        if (!motif.fontFamily && typeof token.value !== 'string') {
          motif.fontFamily = token.value.fontFamily as FontFamily;
        }
        // We don't parse typography. We use the constituent values instead.
        continue;
      }

      const cssVar = token.description as MotifVar;
      const parsedValue = parseValue(token, currentPath);

      // Record core values.
      figmaTokenReferences.set(currentPath, parsedValue);

      if (allMotifVars.has(cssVar)) {
        motifVariableReferences.set(currentPath, `var(${cssVar})`);
      }

      let cssValue: string = parsedValue;

      // NOTE: only doing 4 iterations to avoid infinite loops.
      for (let i = 0; i < 4; i++) {
        if (!cssValue.includes('{')) break;

        for (const [match] of cssValue.matchAll(/\{[^}]+\}/g)) {
          if (!match) continue;
          const tokenRef = match!.substring(1, match.length - 1);
          const replacement =
            motifVariableReferences.get(tokenRef) ?? figmaTokenReferences.get(tokenRef);

          if (!replacement) {
            // We report the error later in the flow.
            continue;
          }
          cssValue = cssValue.replace(match, replacement);
        }
      }

      if (cssValue.includes('{')) {
        maybeRetry(
          `Unable to resolve reference for ${red(cssValue)}"\n${gray(
            `Path: ${schemeName} / ${currentPath})`
          )}`
        );
        continue;
      }

      // The description field has the name of the exported CSS
      // variable. So we should be able to map it back.

      const component = componentByVariable[cssVar];

      if (!component) {
        // NOTE: Typography is skipped earlier in the parsing function.

        // Skipping globals. It's OK for designers to put in intermediate variables
        // there.
        if (schemeName === 'Snap Motif/Global') continue;

        onError?.(
          new Error(
            `Unable to find a component for "${redBright(cssVar)}".\n${gray(
              `Path: ${schemeName} / ${currentPath}`
            )}`
          )
        );
        continue;
      }

      // @ts-ignore It's OK. It fails with "Expression produces a union type that is too complex to represent."
      if (!motif[scheme]![component]) motif[scheme]![component] = {};

      // Hacky way to handle gradients since its the same token in figma, but maps to
      // different CSS properties.
      if (
        component === MotifComponent.ROOT &&
        cssVar === '--bg-color' &&
        cssValue.includes('gradient')
      ) {
        // @ts-ignore It's OK. It fails with readonly but it works fine.
        motif[scheme]![component]!['--bg-image'] = cssValue;
        // @ts-ignore It's OK. It fails with readonly but it works fine.
        motif[scheme]![component]!['--bg-color'] = 'transparent';
      }

      // @ts-ignore It's OK. I (Alex) couldn't figure out the right type cast.
      motif[scheme]![component]![cssVar] = cssValue;
    }
  }

  return motif;
}
