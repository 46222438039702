import { css } from '@emotion/css';
import { m, ZIndex } from '@snapchat/snap-design-system-marketing';

export const globalHeaderSearchContainerDesktopOpenCss = css`
  width: 500px;
  &&& {
    margin-left: auto;
  }
`;

export const globalHeaderSearchContainerMobileCss = css`
  width: 100%;
  &&& {
    margin-left: auto;
  }
`;

export const searchOpenCss = css`
  & > *:first-child {
    display: flex;
    flex-grow: 0;
    flex-basis: initial;
    flex-basis: content;
  }
`;

export const siteNameCss = css`
  display: inline-block;
  cursor: pointer;
  color: ${m('--fg-color')};
  text-decoration: none;
`;

export const headerContainerCss = css`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: ${ZIndex.NAVIGATOR};
`;

export const noMarginCss = css`
  margin: 0;
`;
