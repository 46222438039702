import { cx } from '@emotion/css';
import type { FC } from 'react';

import { h3Css } from '../../motif';
import { statSubtopicCss, subtopicCss } from './styles';
import type { StatSubtopicProps } from './types';

/**
 * A component for displaying complementary details to support main content using stats.
 *
 * This component is designed to be used within the Multi Value Prop Block component that provides
 * structure and layout.
 *
 * @param {string} [stat] - The main stat value to display.
 * @param {ReactNode} [subtitle] - An optional subtitle to provide context for the stat.
 */

export const StatSubtopic: FC<StatSubtopicProps> = ({ stat, subtitle }) => {
  return (
    <div className={cx(subtopicCss, statSubtopicCss)}>
      <h3 className={h3Css}>{stat}</h3>
      {subtitle}
    </div>
  );
};
