import { css } from '@emotion/css';

import { maxWidth, mobileMediaQuery, White } from '../../constants';
import { m } from '../../motif/m';
import { desktopSideNavWidthPx } from '../SideNavigation/styles';

export const containerCss = css`
  background-color: ${m('--bg-color')};
  position: relative;
  width: 100%;
`;

/** Need set bg to transparent if bg img or video */
export const pageTransparentBgCss = css`
  background-color: transparent;
`;

export const backgroundImageCss = (
  backgroundImage?: string,
  mobileBackgroundImage?: string
): string | undefined =>
  backgroundImage
    ? css`
        background: center / cover url(${backgroundImage});

        ${mobileMediaQuery} {
          background: center / cover
            url(${mobileBackgroundImage ? mobileBackgroundImage : backgroundImage});
        }
      `
    : undefined;

export const fixedBackgroundImageCss = css`
  background-attachment: fixed;
`;

export const backgroundMediaCss = css`
  position: absolute;
  object-fit: cover;
  height: 100%;
  width: 100%;
  z-index: -1;
`;

export const fixedBackgroundMediaCss = css`
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
`;

// TODO: we're forcing the background to white as we don't have validated designs
// for other themes and background-color only applies Block and Navigator (not Page).
// possible solution: apply the background of the first block
export const sideNavigationWrapperCss = css`
  display: flex;
  justify-content: center;

  background-color: ${White};

  ${mobileMediaQuery} {
    flex-direction: column;
  }
`;

export const sideNavigationWrapperBlocksCss = css`
  flex: 0 1 ${maxWidth - desktopSideNavWidthPx}px;
  min-width: 0px;

  ${mobileMediaQuery} {
    flex: none;
  }
`;
