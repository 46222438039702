import { cx } from '@emotion/css';
import type { FC, ReactNode } from 'react';
import { useContext } from 'react';

import { MotifComponent, useMotifStyles } from '../../motif';
import type { BaseComponentProps, ImageSources } from '../../types';
import { dataSetToAttributes, formatDateToDatetime } from '../../utils';
import { Picture } from '../Picture';
import { PrimitivesContext } from '../Primitives';
import {
  summaryCardAnchorCss,
  summaryCardContainerCss,
  summaryCardCss,
  summaryCardDateCss,
  summaryCardDescriptionCss,
  summaryCardDescriptionMediaCss,
  summaryCardDescriptionNoMediaCss,
  summaryCardImageCss,
  summaryCardPictureCss,
  summaryCardRowCss,
  summaryCardTitleCss,
  summaryCardTitleMediaDescriptionCss,
  summaryCardTitleMediaNoDescriptionCss,
  summaryCardTitleNoDateCss,
  summaryCardTitleNoMediaDescriptionCss,
  summaryCardTitleNoMediaDescriptionNoDateCss,
  summaryCardTitleNoMediaNoDescriptionCss,
} from './SummaryCard.styled';

export interface SummaryCardProps extends BaseComponentProps {
  date?: string;
  dateTime?: Date;
  description?: ReactNode;
  title?: ReactNode;
  onClick?: () => void;
  link?: string;
  className?: string;
  imgSrcs?: ImageSources;
  imgAltText?: string;
  showMedia?: boolean;
  showDescription?: boolean;
  showDate?: boolean;
  titleDataset?: DOMStringMap;
  descriptionDataset?: DOMStringMap;
  dateDataset?: DOMStringMap;
  mediaDataset?: DOMStringMap;
  eyebrowText?: string;
}

export const SummaryCard: FC<SummaryCardProps> = ({
  date,
  dateTime,
  className,
  title,
  description,
  onClick,
  link,
  imgSrcs,
  imgAltText,
  showMedia,
  showDescription,
  showDate,
  dataset,
  dateDataset,
  titleDataset,
  descriptionDataset,
  mediaDataset,
  eyebrowText,
}) => {
  useMotifStyles(MotifComponent.SUMMARY_CARD);
  const { Anchor } = useContext(PrimitivesContext);

  const rootClassName = cx(
    MotifComponent.SUMMARY_CARD,
    {
      [summaryCardCss]: !link,
      [summaryCardRowCss]: !!link,
    },
    className
  );

  const anchorRootClassName = cx(
    summaryCardAnchorCss,
    {
      [summaryCardCss]: !!link,
    },
    className
  );

  const summaryCardTitleClassName = cx(summaryCardTitleCss, {
    [summaryCardTitleMediaDescriptionCss]: showDescription && showMedia,
    [summaryCardTitleNoMediaNoDescriptionCss]: !showDescription && !showMedia,
    [summaryCardTitleNoMediaDescriptionCss]: showDescription && !showMedia && showDate,
    [summaryCardTitleNoMediaDescriptionNoDateCss]: !showDescription && !showMedia && !showDate,
    [summaryCardTitleMediaNoDescriptionCss]: !showDescription && showMedia,
    [summaryCardTitleNoDateCss]: !date && showDate,
  });

  const summaryCardDescriptionClassName = cx(summaryCardDescriptionCss, {
    [summaryCardDescriptionMediaCss]: showMedia,
    [summaryCardDescriptionNoMediaCss]: !showMedia,
  });

  const innerContainer = (
    <div
      className={rootClassName}
      data-testid="summary-card-container"
      {...dataSetToAttributes(dataset)}
    >
      <div className={summaryCardContainerCss}>
        {eyebrowText ? (
          <span className={summaryCardDateCss}>{eyebrowText}</span>
        ) : showDate && date ? (
          <time
            dateTime={formatDateToDatetime(dateTime)}
            className={summaryCardDateCss}
            {...dataSetToAttributes(dateDataset)}
          >
            {date}
          </time>
        ) : null}
        {title ? (
          <div className={summaryCardTitleClassName} {...dataSetToAttributes(titleDataset)}>
            {title}
          </div>
        ) : null}
        {showDescription && description ? (
          <div
            className={summaryCardDescriptionClassName}
            {...dataSetToAttributes(descriptionDataset)}
          >
            {description}
          </div>
        ) : null}
      </div>
      {showMedia ? (
        <div className={summaryCardImageCss}>
          {imgSrcs ? (
            <Picture
              altText={imgAltText}
              imgSrcs={imgSrcs}
              imgClassName={summaryCardPictureCss}
              dataset={mediaDataset}
            />
          ) : null}
        </div>
      ) : null}
    </div>
  );

  if (link) {
    return (
      <Anchor href={link} className={anchorRootClassName} onClick={onClick}>
        {innerContainer}
      </Anchor>
    );
  }

  return innerContainer;
};

SummaryCard.displayName = 'SummaryCard';
