import { BLOCKS } from '@contentful/rich-text-types';
import type { CallToAction } from '@snapchat/mw-contentful-schema';
import { BackgroundColor, MessageContext } from '@snapchat/snap-design-system-marketing';
import type { FC, ReactNode } from 'react';
import { useContext } from 'react';
import { useLocation } from 'react-router-dom';

import { useContentfulQuery } from '../../hooks/useContentfulQuery';
import type { Items } from '../../types/Items';
import type { RichText } from '../../types/RichText';
import type { BlockDataProps } from '../Block';
import type { SubNavigationDataProps } from '../SubNavigation';
import type { HeroDataProps } from '.';
import { Hero } from './Hero';
import type { LatestPostHeroProps } from './latestPostQuery';
import { latestPostHeroQuery } from './latestPostQuery';

export const LatestPostHero: FC<{
  backgroundColor?: BackgroundColor;
  postChildren?: ReactNode;
}> = ({ backgroundColor, postChildren }) => {
  const { data } = useContentfulQuery<LatestPostHeroProps>(latestPostHeroQuery);
  const { formatMessage } = useContext(MessageContext);
  const { pathname } = useLocation();
  const latestPostCtaTitle = formatMessage({ id: 'viewArticle', defaultMessage: 'View Article' });

  const titleCtaRichText: RichText = {
    json: {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks: [],
              value: latestPostCtaTitle,
              nodeType: 'text',
            },
          ],
          nodeType: BLOCKS.PARAGRAPH,
        },
      ],
      nodeType: BLOCKS.DOCUMENT,
    },
    links: undefined,
  };

  const slugData = data?.slugCollection.items[0];

  const slug = slugData?.slug;
  let blockHero: HeroDataProps | undefined;

  switch (slugData?.page.__typename) {
    case 'Page':
      blockHero = slugData.page.blocksCollection.items.find(
        (item): item is HeroDataProps => item.__typename === 'BlockHero'
      );
      break;

    case 'PageAsset':
      blockHero = {
        __typename: 'BlockHero',
        sys: { id: 'asset_block_hero' },
        title: slugData?.page?.media?.title,
        body: slugData?.page?.media?.description,
      };
      break;

    case 'Experiment': {
      blockHero = (
        slugData.page.defaultReference as unknown as {
          blocksCollection: Items<BlockDataProps | HeroDataProps | SubNavigationDataProps>;
        }
      ).blocksCollection.items.find(
        (item): item is HeroDataProps => item.__typename === 'BlockHero'
      );
      break;
    }
  }

  const callsToActionCollection: Items<CallToAction> = {
    items: [
      {
        _id: 'generated',
        sys: {
          id: 'generated',
        },
        url: slug,
        analytics: {
          _id: 'generated',
          sys: {
            id: 'generated',
          },
          label: `Latest Post Hero CTA: ${pathname}`,
        },
        presentation: {
          __typename: 'Button',
          _id: 'generated',
          sys: {
            id: 'generated',
          },
          url: slug,
          title: titleCtaRichText,
          buttonType: 'Primary',
        },
      },
    ],
  };

  if (!blockHero) return null;

  const heroBackgroundColor = blockHero.backgroundColor ?? backgroundColor ?? BackgroundColor.White;

  backgroundColor ??= BackgroundColor.White;
  return (
    <Hero
      {...blockHero}
      callsToActionCollection={callsToActionCollection}
      backgroundColor={heroBackgroundColor}
      theme={backgroundColor}
      postChildren={postChildren}
    />
  );
};
