import type { TextSubtopic as TextSubtopicType } from '@snapchat/mw-contentful-schema';
import { TextSubtopic as TextSubtopicSDS } from '@snapchat/snap-design-system-marketing';
import type { FC } from 'react';

import { useContentfulImages } from '../../../../contentful-client/src';
import type { RichText } from '../../types/RichText';
import { combineImageSources } from '../../utils/combineImageSources';
import { renderRichTextMultiLineWithMarkings } from '../../utils/renderText/renderRichText';

export const TextSubtopic: FC<TextSubtopicType> = props => {
  const { getImageSources } = useContentfulImages();

  const { title = '', icon, body } = props;

  let imgSrcs;

  if (icon?.media) {
    imgSrcs = combineImageSources({
      desktop: getImageSources(icon?.media?.url),
    });
  }

  return (
    <TextSubtopicSDS
      title={title}
      body={renderRichTextMultiLineWithMarkings(body as RichText)}
      imgSrcs={imgSrcs}
      icon={icon?.icon}
    />
  );
};
