import { css } from '@emotion/css';

import { mobileMediaQuery, nonMobileMediaQuery } from '../../constants';
import { m } from '../../motif/m';
import { blockPadding } from '../Block/Block.styled';
import { imgWidth } from '../Tile';

export const gutterWidth = 52;

export const galleryWidths = [
  (imgWidth + gutterWidth) * 4,
  (imgWidth + gutterWidth) * 3,
  (imgWidth + gutterWidth) * 2,
  imgWidth + gutterWidth,
];

const screenWidths = galleryWidths.map(w => w + blockPadding * 2);

export const mediaQueries = screenWidths.map((width, idx) => {
  if (idx === 0) {
    return `@media screen and (min-width: ${width + 1}px)`;
  }
  const prevWidth = screenWidths[idx - 1];
  return `@media screen and (min-width: ${width + 1}px) and (max-width: ${prevWidth}px)`;
});

export const desktopWrapperCss = css`
  ${mobileMediaQuery} {
    display: none;
  }

  ${nonMobileMediaQuery} {
    display: block;
  }
`;

export const mobileWrapperCss = css`
  ${mobileMediaQuery} {
    display: block;
  }

  ${nonMobileMediaQuery} {
    display: none;
  }
`;

export const buttonDefaultStylesResetCss = css`
  background: none;
  border: none;
  color: ${m('--fg-color')};
  cursor: pointer;
  padding: 0;
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-appearance: none;
  appearance: none;
`;

export const searchWrapperCss = css`
  display: flex;

  ${mediaQueries[0]} {
    width: ${galleryWidths[0]}px;
  }

  ${mediaQueries[1]} {
    width: ${galleryWidths[1]}px;
  }

  ${mediaQueries[2]} {
    width: ${galleryWidths[2]}px;
  }

  ${nonMobileMediaQuery} {
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: 50px;
  }

  ${mobileMediaQuery} {
    flex-direction: column;
    justify-content: flex-end;
    margin-block: ${m('--spacing-m')};
  }
`;

export const filterBarCss = css`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-block: ${m('--spacing-m')};
`;

export const toggleDropdownsBaseTextCss = css`
  font-size: ${m('--action-desktop-font-size')};
  line-height: ${m('--action-desktop-font-line-height')};

  ${mobileMediaQuery} {
    font-size: ${m('--action-mobile-font-size')};
    line-height: ${m('--action-mobile-font-line-height')};
  }
`;

export const toggleDropdownsMainTextCss = css`
  font-family: inherit;
  font-weight: ${m('--action-desktop-font-weight')};

  ${mobileMediaQuery} {
    font-weight: ${m('--action-mobile-font-weight')};
  }
`;

export const toggleDropdownsSecondaryTextCss = css`
  font-weight: ${m('--p1-desktop-font-weight')};

  ${mobileMediaQuery} {
    font-weight: ${m('--p1-mobile-font-weight')};
  }
`;

export const clearFiltersWrapperCss = css`
  align-items: center;
  display: flex;
`;

export const clearFiltersButtonCss = css`
  font-family: inherit;
  font-size: ${m('--action-mobile-font-size')};
  font-weight: ${m('--action-mobile-font-weight')};
  line-height: ${m('--action-mobile-font-line-height')};
  margin-inline-end: ${m('--spacing-m')};

  ${nonMobileMediaQuery} {
    padding-block: ${m('--spacing-m')};
    font-size: ${m('--action-desktop-font-size')};
    font-weight: ${m('--action-desktop-font-weight')};
    line-height: ${m('--action-desktop-font-line-height')};
  }
`;

export const filtersIconButtonCss = css`
  align-items: center;
  display: flex;
  width: ${m('--spacing-l')};
`;

export const filtersIconCss = css`
  fill: ${m('--fg-color')};
  height: auto;
  transform: rotate(0deg);
  transform-origin: center;
  transition: transform 100ms cubic-bezier(0.4, 0, 0.2, 1);
  width: 100%;
`;

export const filtersIconActiveCss = css`
  transform: rotate(-180deg);
`;
